import { ReactNode, useEffect, useRef } from 'react'
import 'twin.macro'

interface Props {
  children: ReactNode
  onClose: Function
  nonRes?: boolean
}

const MessageModal = ({ onClose, children, nonRes }: Props) => {
  const hideButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    hideButtonRef.current?.focus()
  }, [])

  return (
    <div
      tw="w-330 min-h-180 bg-white rounded-md p-20 flex flex-col"
      className="shadowBase"
    >
      <div tw="text-16 py-10 px-5 break-keep">{children}</div>
      <div tw="mt-auto ml-auto flex gap-10">
        <button ref={hideButtonRef} tw="w-0 h-0"></button>
        <button
          tw="w-80"
          className="button base lightBrown"
          onClick={() => onClose(true)}
        >
          확인
        </button>
        {!nonRes && (
          <button
            tw="w-80"
            onClick={() => onClose(false)}
            className="button base gray"
          >
            취소
          </button>
        )}
      </div>
    </div>
  )
}

export default MessageModal
