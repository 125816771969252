import { DisplayStandForm } from '@components/exhibition/FormFragments/DisplayStandFrom'
import { exhibitionState } from '@store/exhibition'
import { useRecoilState } from 'recoil'

const useDisplayStandForms = () => {
  const [exhibitionForm, setExhibitionForm] = useRecoilState(exhibitionState)

  const setDisplayStandForm = (formValue: DisplayStandForm) => {
    const filteredPrevFormValues = exhibitionForm.displayStandFormValues.filter(
      ({ number }) => formValue.number !== number
    )
    exhibitionForm.displayStandFormValues = filteredPrevFormValues
    const currentLoadedExhibitionForm = { ...exhibitionForm }
    currentLoadedExhibitionForm.displayStandFormValues.push(formValue)
    setExhibitionForm(currentLoadedExhibitionForm)
  }

  const getDisplayStandForm = (formNumber: number) => {
    const finedDisplayStandForm = exhibitionForm.displayStandFormValues.find(
      ({ number }) => formNumber === number
    )
    return finedDisplayStandForm
  }

  return { getDisplayStandForm, setDisplayStandForm }
}

export { useDisplayStandForms }
