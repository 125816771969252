import { accountManagementRemote } from './service/accountService/remote'
import { authServiceRemote } from './service/authService/remote'
import { boardServiceRemote } from './service/boardService/remote'
import { exhibitionServiceRemote } from './service/exhibitionService/remote'
import { guestBookServiceRemote } from './service/guestBookService/remote'
import { mediaServiceRemote } from './service/mediaService/remote'
import { statisticsServiceRemote } from './service/statisticsService/remote'

const provideAPIService = () => {
  const authService = authServiceRemote()
  const accountManagementService = accountManagementRemote()
  const mediaService = mediaServiceRemote()
  const boardService = boardServiceRemote()
  const statisticsService = statisticsServiceRemote()
  const guestBookService = guestBookServiceRemote()
  const exhibitionService = exhibitionServiceRemote()

  return {
    authService,
    accountManagementService,
    mediaService,
    boardService,
    statisticsService,
    guestBookService,
    exhibitionService,
  }
}

export const api = provideAPIService()
