import { ReactNode, useRef } from 'react'
import ReactDOM from 'react-dom'

interface Props {
  children: ReactNode
  container: Element | DocumentFragment
}

const PortalToElement = ({ children, container }: Props) => {
  const containerRef = useRef(container)
  return ReactDOM.createPortal(children, containerRef.current)
}

export default PortalToElement
