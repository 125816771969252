import { BadRequestError } from '@api/error/remoteError'
import { api } from '@api/index'
import AgeSelect from '@components/form/AgeSelect'
import AuthoritiesSelect from '@components/form/AuthoritiesSelect'
import FormError from '@components/form/FormError'
import GenderSelect from '@components/form/GenderSelect'
import IdInput from '@components/form/IdInput'
import NicknameInput from '@components/form/NicknameInput'
import PasswordConfirmInput from '@components/form/PasswordConfirmInput'
import PasswordInput from '@components/form/PasswordInput'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import { USER_ROLE } from '@constant/user'
import useAuth from '@hooks/useAuth'
import useModal from '@hooks/useModal'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import 'twin.macro'

interface CreateAdminForm {
  username: string
  password: string
  confirmPassword: string
  nickname: string
  gender: string
  age: string
  authorities: USER_ROLE | 'pending'
}

const CreateAdmin = () => {
  const navigate = useNavigate()
  const { isSuperAdmin } = useAuth()
  const { openModal } = useModal()
  useEffect(() => {
    if (!isSuperAdmin()) {
      openModal((onClose) => (
        <MessageModal onClose={onClose}>
          {MESSAGE.error.forbidden.notSuperAdmin}
        </MessageModal>
      ))
      navigate('/admin')
    }
  }, [])

  const methods = useForm<CreateAdminForm>({
    mode: 'onChange',
    defaultValues: {
      gender: 'pending',
      age: 'pending',
      authorities: 'pending',
    },
  })
  const { handleSubmit, setError } = methods

  const createAdminMutation = useMutation(
    ['createAdmin'],
    (formValue: CreateAdminForm) =>
      api.accountManagementService.createAdmin(
        formValue.username,
        formValue.password,
        formValue.confirmPassword,
        formValue.nickname,
        formValue.gender,
        formValue.age,
        formValue.authorities as USER_ROLE
      )
  )

  const handleTestFormSubmit = (formValue: CreateAdminForm) => {
    createAdminMutation.mutate(formValue, {
      async onSuccess() {
        const modalRes = await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.created.admin}
          </MessageModal>
        ))
        if (modalRes) navigate('/admin/account-management')
      },
      async onError(err) {
        if (err instanceof BadRequestError) {
          Object.entries(err.data.message).forEach(([field, message]) =>
            setError(field as any, {
              type: 'custom',
              message: message as string,
            })
          )
        }
      },
    })
  }

  return (
    <ContentWrapper breadCrumbPath={['계정관리', '관리자 추가']}>
      <CardSection className="min-h-830">
        <FormProvider<CreateAdminForm> {...methods}>
          <form
            id="create-admin-form"
            tw="w-full min-h-600 pt-100 flex-center flex-col under:w-350"
            onSubmit={handleSubmit(handleTestFormSubmit)}
          >
            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                아이디
              </label>
              <IdInput
                id="username"
                tw="flex-1 border-1 placeholder:text-gray-400"
                className="input"
              />
            </div>
            <FormError<CreateAdminForm> targetInputId="username" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                비밀번호
              </label>
              <PasswordInput
                id="password"
                tw="flex-1 border-1 placeholder:text-gray-400"
                className="input"
              />
            </div>
            <div></div>
            <FormError<CreateAdminForm> targetInputId="password" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                비밀번호 확인
              </label>
              <PasswordConfirmInput
                tw="flex-1 border-1 placeholder:text-gray-400"
                id="confirmPassword"
                className="input"
                passwordInputId="password"
              />
            </div>
            <FormError<CreateAdminForm> targetInputId="confirmPassword" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                닉네임
              </label>
              <NicknameInput
                id="nickname"
                tw="flex-1 border-1 placeholder:text-gray-400"
                className="input"
              />
            </div>
            <FormError<CreateAdminForm> targetInputId="nickname" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                성별
              </label>
              <GenderSelect
                id="gender"
                tw="flex-1 py-0 px-10 border-1"
                className="input"
              />
            </div>
            <FormError<CreateAdminForm> targetInputId="gender" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                나이
              </label>
              <AgeSelect
                id="age"
                tw="flex-1 py-0 px-10 border-1"
                className="input"
              />
            </div>
            <FormError<CreateAdminForm> targetInputId="age" />

            <div tw="relative flex gap-15 items-center">
              <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
                등급
              </label>
              <AuthoritiesSelect
                id="authorities"
                tw="flex-1 py-0 px-10 border-1"
                className="input"
              />
            </div>

            <FormError<CreateAdminForm> targetInputId="authorities" />
          </form>
        </FormProvider>
        <div tw="h-40 flex justify-center all:w-165 gap-20 all:font-pretendardM all:tracking-wider">
          <LoadableButton
            className="button base lightBrown"
            form="create-admin-form"
            loadingKey="createAdmin"
          >
            추가
          </LoadableButton>
          <BackSpaceButton className="button base darkBrown">
            취소
          </BackSpaceButton>
        </div>
      </CardSection>
    </ContentWrapper>
  )
}

export default CreateAdmin
