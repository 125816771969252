import { defaultExhibitionFormState, exhibitionState } from '@store/exhibition'
import { useRecoilState } from 'recoil'
const useExhibitionFormState = () => {
  const [exhibitionForm, setExhibitionForm] = useRecoilState(exhibitionState)

  const setFetchDataToForm = (fetchData: ExhibitionInfo) => {
    const copied = { ...exhibitionForm }
    copied.exhibitionId = fetchData.id
    copied.exhibitionName = fetchData.name
    copied.displayStandFormValues = [...fetchData.displayStands]
    copied.sculptureFormValues = [...fetchData.sculptures]
    setExhibitionForm(copied)
  }

  const reset = () => {
    setExhibitionForm(defaultExhibitionFormState)
  }

  return { exhibitionForm, setExhibitionForm, setFetchDataToForm, reset }
}

export { useExhibitionFormState }
