import FormError from '@components/form/FormError'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import LoadableButton from '@components/shared/LoadableButton'
import { MESSAGE } from '@constant/message'
import { useExhibitionFormState } from '@hooks/useExhibitionFormState'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import StepDot from '../StepDot'

interface ExhibitionTitleFormValue {
  exhibitionName: string
}

interface Props {
  nextStep: Function
}

const ExhibitionTitleForm = ({ nextStep }: Props) => {
  const { exhibitionForm, setExhibitionForm } = useExhibitionFormState()
  const methods = useForm<ExhibitionTitleFormValue>({
    mode: 'onChange',
    defaultValues: {
      exhibitionName: exhibitionForm.exhibitionName,
    },
  })

  const { handleSubmit, register } = methods

  const handleExhibitionTitleFormSubmit = (
    formValue: ExhibitionTitleFormValue
  ) => {
    setExhibitionForm((prev) => ({ ...prev, ...formValue }))
    nextStep()
  }

  return (
    <FormProvider<ExhibitionTitleFormValue> {...methods}>
      <form
        id="exhibition-title-form"
        tw="w-full flex flex-col under:w-550 items-center"
        onSubmit={handleSubmit(handleExhibitionTitleFormSubmit)}
      >
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            기획전 이름
          </label>
          <input
            {...register('exhibitionName', {
              required: MESSAGE.exhibition.require.name,
            })}
            tw="w-full flex-1"
            className="input"
            placeholder="기획전 이름을 입력해 주세요."
          ></input>
        </div>
        <div tw="flex gap-20 items-center">
          <label tw="basis-120"></label>
          <FormError<ExhibitionTitleFormValue> targetInputId="exhibitionName" />
        </div>
      </form>
      <StepDot tw="mt-30" />
      <div tw="flex justify-center all:w-150 gap-20 mt-30">
        <BackSpaceButton className="button base darkBrown">
          취소
        </BackSpaceButton>
        <LoadableButton
          className="button base lightBrown"
          form="exhibition-title-form"
          loadingKey={'createExhibitionRoot'}
        >
          다음
        </LoadableButton>
      </div>
    </FormProvider>
  )
}

export default ExhibitionTitleForm
