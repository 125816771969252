import { Component, ReactElement, ReactNode } from 'react'

type fallbackType = {
  error: Error
  resetBoundary?: () => void
}

interface Props {
  children: ReactNode
  fallback: (fallbackCallbackArg: fallbackType) => ReactElement
}

interface State {
  hasError: boolean
  error: Error | null
}

const initialState: State = { hasError: false, error: null }

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialState
  }

  /**
   * 하위 컴포넌트로부터 Error 감지
   */
  static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error: error }
  }

  /**
   * Error 초기화
   */
  resetBoundary = () => {
    this.setState(initialState)
  }

  /**
   * SideEffect 허용 Error 기록용
   */
  //componentDidCatch(error: Error, errorInfo: ErrorInfo) {}

  render(): ReactNode {
    if (this.state.hasError && this.state.error) {
      return this.props.fallback({
        error: this.state.error,
        resetBoundary: this.resetBoundary,
      })
    }
    return this.props.children
  }
}

export default ErrorBoundary
