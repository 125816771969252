const user = {
  require: {
    id: '아이디를 입력해 주세요.',
    password: '비밀번호를 입력해 주세요.',
    passwordConfirm: '비밀번호 확인을 입력해 주세요.',
    nickname: '닉네임을 입력해 주세요.',
    gender: '성별을 선택해 주세요.',
    authorities: '등급을 선택해 주세요.',
    age: '나이대를 선택해 주세요.',
  },
  invalid: {
    id: '사용할 수 없는 아이디입니다.',
    password: '8~16자 영문,숫자,($@$!%*#?&)를 포함해주세요.',
    passwordConfirm: '비밀번호와 일치하지 않습니다.',
    nickname: '사용할 수 없는 닉네임입니다.',
  },
} as const

const media = {
  require: {
    docTitle: '문서 제목을 입력해 주세요.',
    videoTitle: '동영상 제목을 입력해 주세요.',
    videoURL: '동영상 주소를 입력해 주세요.',
  },
  invalid: {
    videoURL: '올바른 형식의 주소를 입력해 주세요.',
  },
}

const file = {
  require: {
    file: '파일을 선택해 주세요.',
  },
  invalid: {
    onlyImage: '이미지 파일만 등록 가능합니다',
    onlyAudio: '음성 파일만 등록 가능합니다',
    onlyPdf: 'pdf 파일만 등록 가능합니다',
    capacity: '선택된 파일의 용량이 너무 큽니다.',
    wrongType: '잘못된 확장자의 파일입니다.',
  },
}

const exhibition = {
  require: {
    name: '기획전 이름을 입력해 주세요',
  },
}

const success = {
  created: {
    admin: '관리자가 추가되었습니다.',
    video: '동영상이 추가되었습니다.',
    docs: '문서가 추가되었습니다.',
    board: '업로드 되었습니다.',
    exhibition: '기획전이 추가되었습니다.',
  },
  modified: {
    exhibition: '기획전이 수정되었습니다.',
  },
  updated: {
    password: '비밀번호가 변경되었습니다.',
    account: '회원정보가 수정되었습니다.',
    unblock: '회원차단이 해제되었습니다.',
    block: '회원을 차단하였습니다.',
    guestBook: '방명록이 수정되었습니다',
    exhibited: '해당 기획전을 전시 처리하였습니다.',
  },
  deleted: {
    account: '계정이 삭제되었습니다',
    media: '미디어가 삭제되었습니다',
    guestBook: '방명록이 삭제되었습니다.',
    exhibition: '기획전이 삭제되었습니다.',
  },
}

const error = {
  forbidden: {
    notSuperAdmin: '권한이 없습니다.',
  },
  badRequest: {
    exhibition: '기획전 정보를 불러오는데 실패하였습니다.',
  },
  upload: {
    exhibition: '기획전 생성이 실패하였습니다. 잠시 후 다시 시도해주세요.',
  },
}

const question = {
  blockAccount: '해당 유저를 차단 하시겠습니까?',
  unblockAccount: '해당 유저를 차단 해제 하시겠습니까?',
  deleteAccount: '해당 유저를 정말로 삭제하시겠습니까?',
  deleteMedia: '해당 미디어를 정말로 삭제 하시겠습니까?',
  deleteGuestBook: '해당 방명록을 정말로 삭제 하시겠습니까?',
  updateGuestBook: '방명록을 수정하시겠습니까?',
  deleteBoardImage: '전광판에 업로드 되어있는 이미지를 삭제하시겠습니까?',
  deleteExhibition: '해당 기획전을 정말로 삭제 하시겠습니까?',
  updateStateExhibition: '해당 기획전을 전시 상태로 변경하시겠습니까?',
}

export const MESSAGE = {
  user,
  file,
  success,
  error,
  exhibition,
  question,
  media,
}
