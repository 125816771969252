import 'twin.macro'
import style from './style.module.scss'

const LoadingDot = () => {
  return (
    <div className={style.loading}>
      <div tw="bg-c-brown-300"></div>
      <div tw="bg-[#605a51]"></div>
      <div tw="bg-[#8C8478]"></div>
      <div tw="bg-[#ada190]"></div>
    </div>
  )
}

export default LoadingDot
