const objToFormData = (obj: Record<string, any>) => {
  const ret = new FormData()
  for (const key in obj) {
    if (key === 'number') {
      ret.append('number', obj[key])
      continue
    }
    if (obj[key]) {
      ret.append(key, obj[key])
    }
  }
  return ret
}

export default objToFormData
