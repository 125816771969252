import { ForbiddenError, UnauthorizedError } from '@api/error/remoteError'
import { EVENT_BUS_KEY } from '@constant/eventBusKey'
import useErrorBoundary from '@hooks/useErrorBoundary'
import eventBus from '@utils/eventBus'
import { useEffect } from 'react'

const RemoteErrorObserver = () => {
  const { throwOnBoundary } = useErrorBoundary()
  useEffect(() => {
    const unauthorizedErrorCallback = () =>
      throwOnBoundary(new UnauthorizedError('401'))
    const forbiddenErrorCallback = () =>
      throwOnBoundary(new ForbiddenError('403'))

    eventBus.on(EVENT_BUS_KEY.UNAUTHORIZED_ERROR, unauthorizedErrorCallback)
    eventBus.on(EVENT_BUS_KEY.FORBIDDEN_ERROR, forbiddenErrorCallback)
  }, [])
  return null
}

export default RemoteErrorObserver
