import { Children, ReactNode, TableHTMLAttributes } from 'react'
import 'twin.macro'
import style from './style.module.scss'

type ColumnItem = [string | any, number]
export type Column = ColumnItem[]

interface Props extends TableHTMLAttributes<HTMLTableElement> {
  children: ReactNode
  column: Column
  emptyMessage: string
}

const BaseTable = ({ column, children, emptyMessage, ...rest }: Props) => {
  const isEmptyTableBody = !Children.count(children)
  return (
    <table
      tw="w-[100%] table-fixed p-0 overflow-hidden rounded-md"
      className={style.table + ' shadowBase'}
      {...rest}
    >
      <thead
        className={style.tableHead}
        tw="border-gray-300 bg-[#A59782] text-white"
      >
        <tr>
          {column.map(([title, width]) => (
            <th
              key={title}
              style={{ width: width + '%' }}
              tw="text-16 px-4 py-5 truncate"
            >
              {title}
            </th>
          ))}
        </tr>
      </thead>
      <tbody
        tw="all:text-15 under:border-gray-300 all:truncate"
        className={style.tableBody}
      >
        {isEmptyTableBody && (
          <tr>
            <td colSpan={column.length}>
              <div>{emptyMessage}</div>
            </td>
          </tr>
        )}
        {children}
      </tbody>
    </table>
  )
}

export default BaseTable
