const eventBus = {
  on(event: string, callback: Function) {
    document.addEventListener(event, (e) => callback(e))
  },
  dispatch(event: string, payload?: Object) {
    document.dispatchEvent(new CustomEvent(event, { detail: payload }))
  },
  remove(event: string, callback: Function) {
    document.removeEventListener(event, (e) => callback(e))
  },
}

export default eventBus
