import { useStepperState } from '@hooks/useStepperState'
import classNames from 'classnames'
import 'twin.macro'

interface Props extends ReactDiv {}

const StepDot = ({ ...rest }: Props) => {
  const { stepper } = useStepperState()
  return (
    <div {...rest} tw="flex gap-15">
      {Array.from({ length: stepper.stepLength }, (_, i) => i).map((order) => (
        <div
          tw="w-10 h-10 rounded-[50%]"
          className={classNames(
            stepper.currentStep === order
              ? 'bg-c-brown-300 scale-125'
              : 'bg-gray-300'
          )}
          key={order}
        ></div>
      ))}
    </div>
  )
}

export default StepDot
