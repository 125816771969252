import {
  CommentIcon,
  DashBoardIcon,
  EnterpriseIcon,
  HallSignIcon,
  KTCCMainIcon,
  MediaIcon,
  UserManageIcon,
} from '@components/shared/Icons'
import 'twin.macro'
import SidebarItem, { SidebarItemProps } from './SidebarItem'

const sidebarItems: SidebarItemProps[] = [
  {
    href: '/admin',
    highlightPath: [],
    children: '대시보드',
    icon: <DashBoardIcon width={18} height={18} stroke={'#2D2D2D'} />,
    highlightIcon: <DashBoardIcon width={18} height={18} stroke={'#673100'} />,
  },
  {
    href: '/admin/account-management',
    highlightPath: [
      '/admin/account-management/create-admin',
      '/admin/account-management/detail',
    ],
    children: '계정관리',
    icon: <UserManageIcon width={18} height={18} stroke={'#2D2D2D'} />,
    highlightIcon: <UserManageIcon width={18} height={18} stroke={'#673100'} />,
  },
  {
    href: '/admin/board-management',
    highlightPath: [],
    children: '광장전광판',
    icon: <HallSignIcon width={18} height={18} stroke={'#2D2D2D'} />,
    highlightIcon: <HallSignIcon width={18} height={18} stroke={'#673100'} />,
  },
  {
    href: '/admin/exhibition-management',
    highlightPath: ['/admin/exhibition-management/add'],
    children: '기획전시실',
    icon: <EnterpriseIcon width={18} height={18} stroke={'#2D2D2D'} />,
    highlightIcon: <EnterpriseIcon width={18} height={18} stroke={'#673100'} />,
  },
  {
    href: '/admin/guest-book-management',
    highlightPath: [],
    children: '방명록',
    icon: <CommentIcon width={18} height={18} stroke={'#2D2D2D'} />,
    highlightIcon: <CommentIcon width={18} height={18} stroke={'#673100'} />,
  },
  {
    href: '/admin/media-management',
    highlightPath: [
      '/admin/media-management/add-docs',
      '/admin/media-management/add-video',
    ],
    children: '미디어',
    icon: (
      <MediaIcon width={18} height={18} stroke={'#2D2D2D'} fill={'#2D2D2D'} />
    ),
    highlightIcon: (
      <MediaIcon width={18} height={18} stroke={'#673100'} fill={'#673100'} />
    ),
  },
]

const Sidebar = () => {
  return (
    <nav
      tw="min-h-900 w-300 basis-300 flex flex-col gap-40 items-center bg-white rounded-md"
      className="shadowBase"
    >
      <a href="https://www.ktcc.or.kr/main/" target={'__blank'}>
        <div tw="mt-62 mb-18">
          <KTCCMainIcon width={190} height={121} />
        </div>
      </a>
      <div tw="w-full">
        {sidebarItems.map((props, idx) => (
          <SidebarItem key={idx} {...props} />
        ))}
      </div>
    </nav>
  )
}

export default Sidebar
