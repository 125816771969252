import ErrorObserver from '@components/auth/RemoteErrorObserver'
import { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import AdminRouter from './AdminRouter'
import LandingRouter from './LandingRouter'

const Router = () => {
  return (
    <>
      <ErrorObserver />
      <Suspense fallback={<></>}>
        <Routes>
          <Route path="/*" element={<LandingRouter />} />
          <Route path="/admin/*" element={<AdminRouter />} />
        </Routes>
      </Suspense>
    </>
  )
}

export default Router
