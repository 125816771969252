import { MESSAGE } from '@constant/message'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'

interface Props extends ReactSelect {
  id: string
}

const AuthoritiesSelect = ({ id, className, disabled }: Props) => {
  const { watch, register, getValues } = useFormContext()

  const isBlockedUser = getValues(id) === 'ROLE_BLOCK'
  const isSuperSuperAdmin =
    getValues('authorities') === 'ROLE_SUPER_SUPER_ADMIN'

  if (isSuperSuperAdmin) {
    return (
      <div
        tw="text-blue-600 font-pretendardSB"
        className={clsx(className, 'view')}
      >
        최고 관리자 (변경불가)
      </div>
    )
  }

  if (isBlockedUser)
    return (
      <div
        tw="text-red-500 font-pretendardSB"
        className={clsx(className, 'view')}
      >
        차단
      </div>
    )

  return (
    <select
      tw="focus:text-black"
      className={clsx(className, watch(id) === 'pending' && 'text-gray-400')}
      disabled={disabled}
      {...register(id, {
        validate(value) {
          //회원수정시 차단된 유저의 경우에는 검증 하지 않음
          if (isBlockedUser) return true
          return (
            /ROLE_USER|ROLE_ADMIN|ROLE_SUPER_ADMIN/g.test(value) ||
            MESSAGE.user.require.authorities
          )
        },
      })}
    >
      <option value="pending" disabled>
        등급을 선택해 주세요.
      </option>
      <option value="ROLE_ADMIN" tw="text-black">
        일반 관리자
      </option>
      <option value="ROLE_SUPER_ADMIN" tw="text-black">
        최고 관리자
      </option>
      <option value="ROLE_USER" tw="text-black">
        일반 유저
      </option>
    </select>
  )
}

export default AuthoritiesSelect
