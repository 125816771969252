import { api } from '@api/index'
import FileInput from '@components/form/FileInput'
import LoadingModal from '@components/shared/LoadingModal'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import { useExhibitionFormState } from '@hooks/useExhibitionFormState'
import useModal from '@hooks/useModal'
import { useSculptureForms } from '@hooks/useSculptureForms'
import { useMutation } from '@tanstack/react-query'
import { deleteNotNecessaryProperty } from '@utils/deleteNotNecessaryProperty'
import queryString from 'query-string'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import 'twin.macro'
import StepDot from '../StepDot'

export type SculptureForm = {
  id?: number
  isValid: boolean
  number: number
  title: string
  frontSide?: File | string
  leftSide?: File | string
  backSide?: File | string
  rightSide?: File | string
  leftHalfSide?: File | string
  rightHalfSide?: File | string
  description?: File | string
  npcGender: string
  text: string
}

interface Props {
  orderNumber: number
  nextStep: Function
  prevStep: Function
  isLast?: boolean
}

const SculptureForm = ({ nextStep, prevStep, orderNumber, isLast }: Props) => {
  const loc = useLocation()
  const { mode } = queryString.parse(loc.search)
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { exhibitionForm } = useExhibitionFormState()
  const { getSculptureForm, setSculptureForm } = useSculptureForms()

  const methods = useForm<SculptureForm>({
    mode: 'onChange',
    defaultValues: {
      ...getSculptureForm(orderNumber),
      number: orderNumber,
      isValid: true,
    },
  })

  const { handleSubmit, register, getValues } = methods

  const handlePrevClick = () => {
    if (isLast) {
      const processedValue = deleteNotNecessaryProperty<SculptureForm>(
        getValues()
      )
      processedValue.isValid = false
      setSculptureForm(processedValue)
      prevStep()
      return
    }
    prevStep()
  }

  const createFinalExhibitionMutation = useMutation(
    ['createFinalExhibition'],
    () => api.exhibitionService.createFinalExhibition(exhibitionForm),
    {
      async onSuccess() {
        await openModal((_, closeAllModals) => (
          <MessageModal nonRes onClose={closeAllModals}>
            {mode
              ? MESSAGE.success.modified.exhibition
              : MESSAGE.success.created.exhibition}
          </MessageModal>
        ))
        navigate('/admin/exhibition-management')
      },
      async onError() {
        await openModal((_, closeAllModals) => (
          <MessageModal nonRes onClose={closeAllModals}>
            {MESSAGE.error.upload.exhibition}
          </MessageModal>
        ))
      },
    }
  )

  const handleSculptureFormSubmit = async (formValue: SculptureForm) => {
    const processedValue = deleteNotNecessaryProperty<SculptureForm>(formValue)
    setSculptureForm(processedValue)
    if (isLast) {
      openModal((onClose) => <LoadingModal onClose={onClose} />)
      createFinalExhibitionMutation.mutate()
      return
    }
    nextStep()
  }

  //이미지 파일 전체를 업로드 하지 않을시 전역상태 저장만 진행하고 페칭은 하지 않기위해 기록
  const handleDisplayStandFromSubmitError = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        <span>
          이미지를 모두 첨부하지 않을시 {orderNumber + 1}번 조형물은 생성되지
          않습니다.
        </span>
        <br />
        {isLast ? (
          <span>그래도 제출하시겠습니까?</span>
        ) : (
          <span>그래도 다음단계로 이동하시겠습니까?</span>
        )}
      </MessageModal>
    ))
    if (!modalRes) return

    const processedValue = deleteNotNecessaryProperty<SculptureForm>(
      getValues()
    )
    processedValue.isValid = false
    setSculptureForm(processedValue)
    if (isLast) {
      openModal((onClose) => <LoadingModal onClose={onClose} />)
      createFinalExhibitionMutation.mutate()
      return
    }
    nextStep()
  }

  return (
    <FormProvider<SculptureForm> {...methods}>
      <form
        id="sculpture-form"
        tw="w-full flex flex-col under:w-550 items-center gap-20"
        onSubmit={handleSubmit(
          handleSculptureFormSubmit,
          handleDisplayStandFromSubmitError
        )}
      >
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물 ${orderNumber + 1} 제목`}
          </label>
          <input
            {...register(`title`)}
            tw="w-full flex-1"
            className="input"
            placeholder="조형물 제목을 입력해 주세요."
          ></input>
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 정면`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`frontSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 60도`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`leftHalfSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 120도`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`leftSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 180도`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`backSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 240도`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`rightSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 300도`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`rightHalfSide`}
            placeholder={'이미지를 선택해 주세요.'}
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물 ${orderNumber + 1} 해설`}
          </label>
          <FileInput
            validation={false}
            tw="w-full flex-1"
            id={`description`}
            placeholder={'해설 음성을 선택해 주세요.'}
            accept="audio/mp3"
            fileExtension={['.mp3']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} NPC`}
          </label>
          <select
            {...register(`npcGender`)}
            tw="py-0 w-full flex-1"
            className="input"
          >
            <option value={'MALE'}>남</option>
            <option value={'FEMALE'}>여</option>
          </select>
        </div>
        {/* 추후 조형물 설명을 추가해달라는 요청이 들어올경우 주석 해제 */}
        {/* <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`조형물${orderNumber + 1} 설명`}
          </label>
          <textarea
            {...register('text')}
            id="age"
            tw="flex-1 resize-none py-5 px-10 h-120 border-1"
            className="input"
          />
        </div> */}
      </form>
      <StepDot tw="mt-30" />
      <div tw="flex justify-center all:w-150 gap-20 mt-30">
        <button onClick={handlePrevClick} className="button base darkBrown">
          이전
        </button>
        <button
          type="submit"
          className="button base lightBrown"
          form="sculpture-form"
        >
          {isLast ? '제출' : '다음'}
        </button>
      </div>
    </FormProvider>
  )
}

export default SculptureForm
