import { api } from '@api/index'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { GrFormClose } from 'react-icons/gr'
import 'twin.macro'
interface Props extends ReactDiv {
  currentImage?: File | string
  clearBlob: Function
  deleteId: string
}

const FileImageView = ({
  currentImage,
  clearBlob,
  deleteId,
  ...rest
}: Props) => {
  const queryClient = useQueryClient()
  const { openModal } = useModal()
  const deleteBoardImageMutation = useMutation(
    () => api.boardService.deleteBoard(deleteId),
    {
      onSuccess() {
        queryClient.invalidateQueries(['boardInfo'])
      },
    }
  )
  const handleDeleteBoardImage = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.deleteBoardImage}
      </MessageModal>
    ))
    if (modalRes) deleteBoardImageMutation.mutate()
  }

  if (!currentImage) {
    return <></>
  }
  if (currentImage instanceof File) {
    return (
      <div {...rest} tw="bg-neutral-100 relative">
        <img
          tw="object-contain w-full h-full"
          src={URL.createObjectURL(currentImage)}
        ></img>
        <button
          onClick={() => clearBlob()}
          tw="bg-white border-2 rounded-sm border-black text-white absolute top-2 right-2"
        >
          <GrFormClose size={20} color={'white'} />
        </button>
      </div>
    )
  }
  return (
    <div {...rest} tw="bg-neutral-100 relative">
      <img
        tw="object-contain w-full h-full"
        src={`${currentImage}?v=${new Date().getTime()}`}
      ></img>
      <button
        type="button"
        onClick={handleDeleteBoardImage}
        tw="bg-white border-2 rounded-sm border-black text-white absolute top-2 right-2"
      >
        <GrFormClose size={20} color={'white'} />
      </button>
    </div>
  )
}

export default FileImageView
