import { api } from '@api/index'
import LoadingDot from '@components/shared/LoadingDot'
import { USER_ROLE } from '@constant/user'
import useAuth from '@hooks/useAuth'
import { removeAccessTokenFromStorage } from '@utils/storage'
import { Navigate, Outlet } from 'react-router-dom'
import 'twin.macro'

const PrivateRouter = () => {
  const { user } = useAuth()
  const isAdmin = () => {
    return user?.authorities.includes(USER_ROLE.ADMIN)
  }

  if (user === undefined) {
    return (
      <div tw="w-full h-full flex items-center justify-center">
        <LoadingDot />
      </div>
    )
  }

  if (user === null) {
    api.authService.deleteDefaultAuthorizationHeader()
    return <Navigate to="/" replace={true} />
  }

  if (!isAdmin()) {
    removeAccessTokenFromStorage()
    api.authService.deleteDefaultAuthorizationHeader()
    return <Navigate to="/?notadmin=true" replace={true} />
  }

  return (
    <>
      <Outlet />
    </>
  )
}

export default PrivateRouter
