import { api } from '@api/index'
import FormError from '@components/form/FormError'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import 'twin.macro'

interface AddVideoForm {
  title: string
  url: string
}

const AddVideo = () => {
  const { openModal } = useModal()
  const navigate = useNavigate()
  const methods = useForm<AddVideoForm>()
  const { register, handleSubmit } = methods

  const addVideoMutation = useMutation(
    ['addVideo'],
    ({ title, url }: AddVideoForm) => api.mediaService.addVideo(title, url),
    {
      async onSuccess() {
        await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.created.video}
          </MessageModal>
        ))
        navigate('/admin/media-management?init_type=url')
      },
    }
  )

  const handleSubmitAddVideoForm = (formValue: AddVideoForm) => {
    addVideoMutation.mutate(formValue)
  }

  return (
    <ContentWrapper breadCrumbPath={['미디어', '동영상 추가']}>
      <CardSection className="flex flex-col items-center justify-center min-h-830">
        <FormProvider {...methods}>
          <form
            id="video-add-form"
            tw="w-full flex flex-col under:w-550 items-center mr-27"
            onSubmit={handleSubmit(handleSubmitAddVideoForm)}
          >
            <div tw="flex gap-20 items-center">
              <label tw="font-pretendardSB rounded-md basis-120 h-35 flex items-center justify-end">
                제목
              </label>
              <input
                {...register('title', {
                  required: MESSAGE.media.require.videoTitle,
                })}
                tw="w-full"
                className="input base"
                placeholder="동영상 제목을 입력해 주세요."
                type="text"
              />
            </div>
            <FormError<AddVideoForm> tw="ml-230" targetInputId={'title'} />
            <div tw="flex gap-20 items-center">
              <label tw="font-pretendardSB rounded-md basis-120 h-35 flex items-center justify-end">
                URL
              </label>
              <input
                {...register('url', {
                  required: MESSAGE.media.require.videoURL,
                })}
                tw="w-full"
                className="input base"
                placeholder="동영상 URL를 입력해 주세요."
                type="text"
              />
            </div>
            <FormError<AddVideoForm> tw="ml-230" targetInputId={'url'} />
          </form>
        </FormProvider>
        <div tw="h-40 flex justify-center all:w-165 gap-20 all:font-pretendardM all:tracking-wider">
          <LoadableButton
            className="button base lightBrown"
            form="video-add-form"
            loadingKey="addVideo"
          >
            추가
          </LoadableButton>
          <BackSpaceButton className="button base darkBrown">
            취소
          </BackSpaceButton>
        </div>
      </CardSection>
    </ContentWrapper>
  )
}

export default AddVideo
