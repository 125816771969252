const user = {
  id: /^[a-z0-9]{5,20}$/,
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,16}/,
  name: /^[가-힣]{2,5}$/,
  nickname: /^[가-힣a-z0-9]{2,8}$/,
  confirm_code: /^\d{6}$/,
  birth: /^.{8,}$/,
} as const

export const REGEXPS = { user }
