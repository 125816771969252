import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { GuestBookService } from './guestBookService'

export const guestBookServiceRemote = (): GuestBookService => ({
  async getGuestBookList(page, size, search) {
    try {
      const response = await baseAPI.get({
        url: `/admin/guestbook`,
        params: { page, size, search, sort: 'id,desc' },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async deleteGuestBook(guestBookUUID) {
    try {
      const response = await baseAPI.delete({
        url: `/admin/guestbook/${guestBookUUID}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async updateGuestBook(guestBookUUID, content) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/guestbook/${guestBookUUID}`,
        data: { content },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
