import useAuth from '@hooks/useAuth'
import { useEffect } from 'react'

const AuthChecker = () => {
  const { refreshUser } = useAuth()

  useEffect(() => {
    refreshUser()
  }, [])
  return null
}

export default AuthChecker
