import { api } from '@api/index'
import LoadingDot from '@components/shared/LoadingDot'
import { useQuery } from '@tanstack/react-query'
import moment from 'moment'
import React, { useState } from 'react'
import 'twin.macro'
import VisitMetricMonthChart from '../VisitMetricMonthChart'
import VisitMetricYearChart from '../VisitMetricYearChart'

const VisitMetricCard = () => {
  const [date, setDate] = useState(moment())
  const [mode, setMode] = useState<'year' | 'month'>('month')
  const isMonthMode = mode === 'month'
  const isYearMode = mode === 'year'

  const handleNextMonth = () => {
    setDate((mo) => {
      return moment(mo.add(1, 'M').format('YYYY-MM'))
    })
  }
  const handlePrevMonth = () => {
    setDate((mo) => {
      return moment(mo.add(-1, 'M').format('YYYY-MM'))
    })
  }
  const handleNextYear = () => {
    setDate((mo) => {
      return moment(mo.add(1, 'y').format('YYYY-MM'))
    })
  }
  const handlePervYear = () => {
    setDate((mo) => {
      return moment(mo.add(-1, 'y').format('YYYY-MM'))
    })
  }

  const { data: monthlyVisited, isLoading: isMonthlyVisitedLoading } = useQuery(
    ['monthlyVisited', date.toISOString()],
    () => api.statisticsService.getMonthlyVisited(date.format('YYYY-MM-DD')),
    {
      cacheTime: 0,
    }
  )

  const { data: yearlyVisited, isLoading: isYearlyVisitedLoading } = useQuery(
    ['yearlyVisited', date.toISOString()],
    () => api.statisticsService.getYearlyVisited(date.format('YYYY')),
    {
      cacheTime: 0,
    }
  )

  return (
    <div
      tw="bg-white h-330 w-full rounded-md overflow-hidden"
      className="shadowBase"
    >
      <div tw="h-55 bg-[#A59782] w-full px-15 flex justify-between items-center">
        <div tw="w-130"></div>
        <div tw="text-white flex gap-15 font-pretendardSB">
          <button onClick={isYearMode ? handlePervYear : handlePrevMonth}>
            {'<'}
          </button>
          <div tw="text-18">
            {isYearMode
              ? date.format('YYYY년 방문자 지표')
              : date.format('YYYY년 MM월 방문자 지표')}
          </div>
          <button onClick={isYearMode ? handleNextYear : handleNextMonth}>
            {'>'}
          </button>
        </div>
        {isYearMode && (
          <button
            onClick={() => setMode('month')}
            tw="w-130"
            className="button base darkBrown"
          >
            월간 방문지표
          </button>
        )}
        {isMonthMode && (
          <button
            onClick={() => setMode('year')}
            tw="w-130"
            className="button base darkBrown"
          >
            연간 방문지표
          </button>
        )}
      </div>
      {isMonthlyVisitedLoading || isYearlyVisitedLoading ? (
        <div tw="w-full h-280 flex justify-center items-center">
          <LoadingDot />
        </div>
      ) : (
        <div>
          {isMonthMode && (
            <VisitMetricMonthChart
              baseDate={date}
              data={monthlyVisited ?? []}
            />
          )}
          {isYearMode && (
            <VisitMetricYearChart baseDate={date} data={yearlyVisited ?? []} />
          )}
        </div>
      )}
    </div>
  )
}

export default React.memo(VisitMetricCard)
