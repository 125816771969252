import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { BoardService } from './boardService'

export const boardServiceRemote = (): BoardService => ({
  async getBoardInfo() {
    try {
      const response = await baseAPI.get({
        url: `/billboard`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async createBoardInfo(BoardInfo) {
    const formData = new FormData()
    Object.entries(BoardInfo).forEach(([key, file]) => {
      formData.append(key, file)
    })
    try {
      const response = await baseAPI.post({
        url: `/admin/billboard`,
        type: `multipart`,
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async deleteBoard(boardName) {
    try {
      const response = await baseAPI.delete({
        url: `/admin/billboard/${boardName}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
