import { RECOIL_KEY } from '@constant/recoilKey'
import { atom } from 'recoil'

interface StepperState {
  currentStep: number
  stepLength: number
}

const defaultStepperState: StepperState = {
  currentStep: 0,
  stepLength: 1,
}

export const stepperState = atom<StepperState>({
  key: RECOIL_KEY.STEPPER,
  default: defaultStepperState,
})
