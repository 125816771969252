import {
  BiChevronLeft,
  BiChevronRight,
  BiChevronsLeft,
  BiChevronsRight,
} from 'react-icons/bi'
import 'twin.macro'

interface Props extends ReactDiv {
  pagelist: number[]
  currentPage: number
  goBeforeSection: Function
  goBefore: Function
  goNextSection: Function
  goNext: Function
  setPage: (page: number) => void
}

const PaginationBar = ({
  pagelist,
  currentPage,
  goBeforeSection,
  goBefore,
  goNextSection,
  goNext,
  setPage,
  ...rest
}: Props) => {
  return (
    <div tw="w-full flex justify-center gap-10" {...rest}>
      <button
        tw=" rounded-md border-none flex items-center border-2 justify-center w-30 h-30"
        onClick={() => goBeforeSection()}
      >
        <BiChevronsLeft size={26} color={'#7B7B7B'} />
      </button>
      <button
        tw=" rounded-md border-none flex items-center border-2 justify-center w-30 h-30"
        onClick={() => goBefore()}
      >
        <BiChevronLeft size={26} color={'#7B7B7B'} />
      </button>
      <ul tw="flex rounded-md relative top-[-1px]" aria-labelledby="pages">
        {pagelist.map((page) => (
          <li
            tw="w-30 h-30 cursor-pointer flex items-center text-18 justify-center"
            onClick={() => setPage(page)}
            className={
              currentPage === page
                ? 'text-c-brown-300 font-pretendardSB scale-110'
                : 'text-gray-500'
            }
            key={page}
          >
            {page}
          </li>
        ))}
      </ul>
      <button
        tw=" rounded-md border-none flex items-center border-2 justify-center w-30 h-30"
        onClick={() => goNext()}
      >
        <BiChevronRight size={26} color={'#7B7B7B'} />
      </button>
      <button
        tw=" rounded-md border-none flex items-center border-2 justify-center "
        onClick={() => goNextSection()}
      >
        <BiChevronsRight size={26} color={'#7B7B7B'} />
      </button>
    </div>
  )
}

export default PaginationBar
