import { USER_ROLE } from '@constant/user'
import 'twin.macro'

interface Props {
  authoritiesArray: USER_ROLE[]
}

const AuthoritiesText = ({ authoritiesArray }: Props) => {
  if (authoritiesArray.includes('ROLE_SUPER_ADMIN'))
    return <span>최고 관리자</span>
  if (authoritiesArray.includes('ROLE_ADMIN')) return <span>일반 관리자</span>
  if (authoritiesArray.includes('ROLE_USER')) return <span>일반 유저</span>
  return <span tw="text-red-500 font-bold">차단</span>
}

export default AuthoritiesText
