import { api } from '@api/index'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import AuthoritiesText from '@components/shared/AuthoritiesText'
import BaseTable from '@components/shared/BaseTable'
import {
  GoogleOAuth,
  KakaoOAuth,
  NaverOAuth,
  NoneOAuth,
} from '@components/shared/Icons'
import PageLoading from '@components/shared/PageLoading'
import PaginationBar from '@components/shared/PaginationBar'
import RouteLink from '@components/shared/RouteLink'
import SearchBar from '@components/shared/SearchBar'
import useAuth from '@hooks/useAuth'
import { useQuery } from '@tanstack/react-query'
import { dateFormat } from '@utils/dateFormat'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import usePagination from 'react-use-pagination-hook'
import 'twin.macro'

const TABLE_ROW_SIZE = 17

const AccountManagement = () => {
  const { isSuperAdmin } = useAuth()
  const navigate = useNavigate()
  const [searchKey, setSearchKey] = useState('')
  const [listMode, setListMode] = useState<'' | '/user'>('/user')
  const isAdminListMode = listMode === ''
  const paginationMethods = usePagination({ numOfPage: 5 })
  const { setTotalPage, currentPage } = paginationMethods

  const { data: accountList, isLoading: isAccountListLoading } = useQuery(
    ['accountList', currentPage, searchKey, listMode],
    () =>
      api.accountManagementService.getAccountList(
        listMode,
        currentPage - 1,
        TABLE_ROW_SIZE,
        searchKey
      ),
    {
      onSuccess(paginationResponse) {
        setTotalPage(paginationResponse.totalPages)
      },
    }
  )

  const handleListRowClick = (userUUID: number) => {
    navigate(`/admin/account-management/detail?id=${userUUID}`)
  }

  return (
    <ContentWrapper breadCrumbPath={['계정관리']}>
      <CardSection>
        <div tw="flex items-center mt-15 gap-10">
          <SearchBar<AccountInfo>
            tw="w-350"
            searchKey="nickname"
            placeholder="닉네임 검색"
            onSearch={setSearchKey}
          />
          {isAdminListMode ? (
            <button
              onClick={() => setListMode('/user')}
              tw="ml-auto"
              className="button base lightBrown"
            >
              전체 목록
            </button>
          ) : (
            <button
              tw="ml-auto"
              onClick={() => setListMode('')}
              className="button base lightBrown"
            >
              관리자 목록
            </button>
          )}
          {isSuperAdmin() && (
            <RouteLink to={'/admin/account-management/create-admin'}>
              <button className="button base darkBrown">관리자 추가</button>
            </RouteLink>
          )}
        </div>
        <div tw="h-690 w-full mt-25">
          {isAccountListLoading && <PageLoading />}
          {!isAccountListLoading && (
            <BaseTable
              emptyMessage={
                searchKey.length
                  ? '검색된 계정이 없습니다.'
                  : '데이터가 없습니다.'
              }
              column={[
                ['순번', 20],
                ['닉네임', 20],
                ['등급', 20],
                ['최종접속일', 20],
              ]}
            >
              {accountList?.content.map(
                ({ id, nickname, authorities, lastLogin, provider }) => (
                  <tr
                    tw="cursor-pointer hover:bg-slate-50"
                    onClick={() => handleListRowClick(id)}
                    key={id}
                  >
                    <td>{id}</td>
                    <td>
                      <div tw="flex gap-10 items-center justify-center">
                        {provider === 'NONE' && (
                          <NoneOAuth width={17} height={17} />
                        )}
                        {provider === 'KAKAO' && (
                          <KakaoOAuth width={17} height={17} />
                        )}
                        {provider === 'GOOGLE' && (
                          <GoogleOAuth width={17} height={17} />
                        )}
                        {provider === 'NAVER' && (
                          <NaverOAuth width={17} height={17} />
                        )}
                        <div>{nickname}</div>
                      </div>
                    </td>
                    <td>
                      <AuthoritiesText authoritiesArray={authorities} />
                    </td>
                    <td>
                      {lastLogin
                        ? dateFormat.fullKebab(moment(lastLogin))
                        : '없음'}
                    </td>
                  </tr>
                )
              )}
            </BaseTable>
          )}
        </div>
      </CardSection>
      <PaginationBar tw="mt-17" {...paginationMethods} />
    </ContentWrapper>
  )
}

export default AccountManagement
