import classNames from 'classnames'
import 'twin.macro'
import LoadingDot from '../LoadingDot'

interface Props {
  covered?: boolean
}

const PageLoading = ({ covered }: Props) => {
  return (
    <div
      tw="w-full h-full flex justify-center items-center"
      className={classNames(
        covered && 'absolute w-full top-0 right-0 bottom-0 left-0 bg-black/5'
      )}
    >
      <LoadingDot />
    </div>
  )
}

export default PageLoading
