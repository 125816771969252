import { api } from '@api/index'
import FileImageView from '@components/board/FileImageView'
import FileInput from '@components/form/FileInput'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import PageLoading from '@components/shared/PageLoading'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { deleteNotNecessaryProperty } from '@utils/deleteNotNecessaryProperty'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'

export interface BoardForm {
  horizontalBillboard1: File | null
  horizontalBillboard2: File | null
  verticalBillboard1: File | null
}

const BoardManagement = () => {
  const { openModal } = useModal()
  const methods = useForm<BoardForm>({
    mode: 'onChange',
  })
  const { handleSubmit, watch, setValue, reset } = methods

  const queryClient = useQueryClient()

  const { data: boardInfo, isFetching: isBoardInfoFetching } = useQuery(
    ['boardInfo'],
    api.boardService.getBoardInfo
  )

  const createBoardInfoMutation = useMutation(
    ['createBoardInfo'],
    (formValue: BoardForm) => api.boardService.createBoardInfo(formValue),
    {
      onSuccess() {
        queryClient.invalidateQueries(['boardInfo'])
        reset()
      },
    }
  )

  const handleCreateBoardFormSubmit = (formValue: BoardForm) => {
    const processedValue = deleteNotNecessaryProperty<BoardForm>(formValue)
    console.log(processedValue)
    createBoardInfoMutation.mutate(processedValue, {
      async onSuccess() {
        openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.created.board}
          </MessageModal>
        ))
      },
    })
  }

  return (
    <ContentWrapper breadCrumbPath={['광장전광판']}>
      <CardSection className="relative flex flex-col items-center justify-center min-h-830">
        {isBoardInfoFetching && <PageLoading covered />}
        <FormProvider<BoardForm> {...methods}>
          <form
            id="board-add-form"
            tw="w-full mt-150 flex flex-col gap-25 under:w-630 items-center justify-center"
            onSubmit={handleSubmit(handleCreateBoardFormSubmit)}
          >
            <div tw="flex gap-15 items-center">
              <label tw="font-pretendardSB rounded-md basis-150 h-35 flex items-center justify-start">
                전광판 1<br /> 권장 1920 x 360
              </label>
              <FileInput
                validation={false}
                id="horizontalBillboard1"
                tw="w-full"
                placeholder="파일을 선택해 주세요."
                accept="image/png"
                fileExtension={['.jpg', '.jpeg']}
              />
            </div>
            <FileImageView
              deleteId="horizontal-billboard1"
              clearBlob={() => setValue('horizontalBillboard1', null)}
              currentImage={
                watch('horizontalBillboard1') || boardInfo?.horizontalBillboard1
              }
              tw="h-300"
            />
            <div tw="flex gap-15 items-center">
              <label tw="font-pretendardSB rounded-md basis-150 w-150 h-35 flex items-center justify-start">
                전광판 2<br /> 권장 863 x 1080
              </label>
              <FileInput
                validation={false}
                id="horizontalBillboard2"
                tw="w-full"
                placeholder="파일을 선택해 주세요."
                accept="image/png"
                fileExtension={['.jpg']}
              />
            </div>
            <FileImageView
              deleteId="horizontal-billboard2"
              clearBlob={() => setValue('horizontalBillboard2', null)}
              currentImage={
                watch('horizontalBillboard2') || boardInfo?.horizontalBillboard2
              }
              tw="h-300"
            />
            <div tw="flex gap-15 items-center">
              <label tw="font-pretendardSB rounded-md basis-150 w-150 h-35 flex items-center justify-start">
                전광판 3<br /> 권장 500 x 900
              </label>
              <FileInput
                validation={false}
                id="verticalBillboard1"
                tw="w-full"
                placeholder="파일을 선택해 주세요."
                accept="image/png"
                fileExtension={['.jpg']}
              />
            </div>
            <FileImageView
              deleteId="vertical-billboard1"
              clearBlob={() => setValue('verticalBillboard1', null)}
              currentImage={
                watch('verticalBillboard1') || boardInfo?.verticalBillboard1
              }
              tw="h-300"
            />
          </form>
        </FormProvider>
        <div tw="h-40 mt-40 mb-150 flex justify-center all:w-165 gap-20 all:font-pretendardM all:tracking-wider">
          <LoadableButton
            className="button base lightBrown"
            form="board-add-form"
            loadingKey="createBoardInfo"
          >
            확인
          </LoadableButton>
          <BackSpaceButton className="button base darkBrown">
            취소
          </BackSpaceButton>
        </div>
      </CardSection>
    </ContentWrapper>
  )
}

export default BoardManagement
