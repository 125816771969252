import { api } from '@api/index'
import { KTCCMainIcon } from '@components/shared/Icons'
import useAuth from '@hooks/useAuth'
import { useMutation } from '@tanstack/react-query'
import queyString from 'query-string'
import { useEffect, useLayoutEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import 'twin.macro'
import style from './style.module.scss'

interface LoginForm {
  userId: string
  password: string
}

const Landing = () => {
  const loc = useLocation()
  const { notadmin, relogin, expire } = queyString.parse(loc.search)
  const { login, reservationRefetchToken, user } = useAuth()
  const navigate = useNavigate()
  const formMethods = useForm<LoginForm>({ mode: 'onChange' })
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = formMethods

  const tokenToUserMutation = useMutation(
    (tokenInfo: TokenInfo) => api.authService.tokenToUser(tokenInfo.token),
    {
      onSuccess(userInfo, tokenInfo) {
        login({ ...userInfo, tokenInfo: tokenInfo })
        reservationRefetchToken(tokenInfo)
      },
    }
  )

  const getAccessTokenMutation = useMutation(
    ({ userId, password }: LoginForm) =>
      api.authService.login(userId, password),
    {
      onSuccess(tokenInfo) {
        tokenToUserMutation.mutate(tokenInfo)
      },
      onError() {
        setError('password', {
          type: 'custom',
          message: '아이디 또는 비밀번호가 잘못 입력되었습니다.',
        })
      },
    }
  )

  const handleLoginFormSubmit = (formValues: LoginForm) => {
    getAccessTokenMutation.mutate(formValues)
  }

  useEffect(() => {
    if (notadmin) {
      setError('password', {
        type: 'custom',
        message: '접근할 수 없는 계정입니다.',
      })
    }
    if (relogin) {
      setError('password', {
        type: 'custom',
        message: '계정정보가 변경되었습니다 다시 로그인해주세요.',
      })
    }
    if (expire) {
      setError('password', {
        type: 'custom',
        message: '세션이 만료되었습니다. 다시 로그인해주세요.',
      })
    }
  }, [])

  useLayoutEffect(() => {
    if (user) {
      navigate('/admin')
    }
  }, [user])

  return (
    <div tw="w-full h-full justify-center items-center">
      <div
        tw="z-[-1] fixed top-0 left-0 right-0 bottom-0"
        className={style.background}
      ></div>
      <div tw="w-350 mx-auto pt-230">
        <div tw="w-full flex justify-center mb-50">
          <KTCCMainIcon width={280} height={180} />
        </div>
        <FormProvider {...formMethods}>
          <form id="login-form" onSubmit={handleSubmit(handleLoginFormSubmit)}>
            <input
              {...register('userId', {
                required: '아이디가 입력되지 않았습니다.',
              })}
              tw="border-none rounded-md w-full py-20 h-46 px-20"
              className="input shadowBase"
              placeholder="아이디를 입력해 주세요."
            />
            <input
              {...register('password', {
                required: '비밀번호가 입력되지 않았습니다.',
              })}
              tw="border-none rounded-md w-full mt-15 py-20 h-46 px-20"
              className="input shadowBase"
              placeholder="비밀번호를 입력해 주세요."
              type="password"
            />
          </form>
          <div tw="py-10 h-42 px-3 text-15 text-center text-red-500">
            {errors.userId && errors.userId.message}
            {!errors.userId && errors.password && errors.password.message}
          </div>
          <div>
            <button
              form="login-form"
              type="submit"
              tw="w-full h-45"
              className="button lightBrown"
            >
              로그인
            </button>
          </div>
        </FormProvider>
      </div>
    </div>
  )
}

export default Landing
