import { ROUTE_PATH } from '@constant/routePath'
import { RefAttributes } from 'react'
import { Link, LinkProps, To } from 'react-router-dom'

type OriginalLinkProps = Omit<
  LinkProps & RefAttributes<HTMLAnchorElement>,
  'to'
>
interface Props extends OriginalLinkProps {
  to: ROUTE_PATH & To
}

interface Props2 extends OriginalLinkProps {
  to: string
}

const RouteLink = ({ children, to, ...rest }: Props | Props2) => {
  return (
    <Link to={to} {...rest}>
      {children}
    </Link>
  )
}

export default RouteLink
