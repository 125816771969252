import { MESSAGE } from '@constant/message'
import { REGEXPS } from '@constant/regexps'
import { sliceToMaxLength } from '@utils/sliceToMaxLength'
import { useFormContext } from 'react-hook-form'

interface Props extends ReactInput {
  id: string
}
const PasswordInput = ({ id, className }: Props) => {
  const { register } = useFormContext()
  return (
    <input
      id={id}
      {...register(id, {
        required: MESSAGE.user.require.password,
        pattern: {
          value: REGEXPS.user.password,
          message: MESSAGE.user.invalid.password,
        },
      })}
      maxLength={16}
      onInput={sliceToMaxLength}
      placeholder="비밀번호를 입력해 주세요."
      className={className}
      type="password"
    />
  )
}

export default PasswordInput
