import {
  localStorage,
  StorageDeleteMethod,
  StorageGetMethod,
  StorageSetMethod,
} from './core'

export const setValueToLocalStorage: StorageSetMethod = (key, data) => {
  if (!localStorage) return
  localStorage.setItem(key, JSON.stringify(data))
}

export const getValueFromLocalStorage: StorageGetMethod = (key) => {
  const raw = localStorage.getItem(key)
  if (!raw) return
  return JSON.parse(raw)
}

export const removeValueFromLocalStorage: StorageDeleteMethod = (key) => {
  localStorage.removeItem(key)
}
