export const RECOIL_KEY = {
  MODAL: 'RECOIL:MODAL',
  USER: 'RECOIL:USER',
  TOS: 'RECOIL:TOS',
  EXHIBITION: 'RECOIL:EXHIBITION',
  EXHIBITION_ROOT: 'RECOIL:EXHIBITION_ROOT',
  STEPPER: 'RECOIL:STEPPER',
} as const

export type RECOIL_KEY = Values<typeof RECOIL_KEY>
