import { FieldValues, useFormContext } from 'react-hook-form'
import 'twin.macro'

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

const FormError = <T extends FieldValues>({
  targetInputId,
  className,
}: Props & { targetInputId: keyof T }) => {
  const {
    formState: { errors },
  } = useFormContext<T>()
  const message = errors[targetInputId]?.message as string
  return (
    <div tw="pt-2 min-h-28 text-15 text-red-500" className={className}>
      {errors[targetInputId] && message}
    </div>
  )
}

export default FormError
