import { api } from '@api/index'
import { USER_ROLE } from '@constant/user'
import { userState } from '@store/user'
import { useMutation } from '@tanstack/react-query'
import {
  getAccessTokenFromStorage,
  removeAccessTokenFromStorage,
  setAccessTokenToStorage,
} from '@utils/storage'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'

const useAuth = () => {
  const loc = useLocation()
  const [refetchCallbackRef, setRefetchCallbackRef] = useState<NodeJS.Timeout>()
  const [user, setUser] = useRecoilState(userState)
  const navigate = useNavigate()

  const isAdmin = () => {
    return user?.authorities.includes(USER_ROLE.ADMIN)
  }

  const isSuperAdmin = () => {
    return user?.authorities.includes(USER_ROLE.SUPER_ADMIN)
  }

  /**
   * user id가 1번인 삭제 및 권한 수정 불가능한 어드민인 경우 체크
   */
  const isSuperSuperAdmin = () => {
    return user?.authorities.includes(USER_ROLE.SUPER_SUPER_ADMIN)
  }

  /**
   * 401시 root path로 replace
   * @author jeongyun
   */
  const replaceRootPathCause401Error = () => {
    removeAccessTokenFromStorage()
    window.location.replace('/?expire=true')
  }

  const replaceRootPathCause403Error = () => {
    removeAccessTokenFromStorage()
    window.location.replace('/?notadmin=true')
  }

  /**
   * 자기 자신의 정보를 수정할시 세션 교체를 위해 강제 로그아웃
   * @author jeongyun
   */
  const requireReLogin = () => {
    removeAccessTokenFromStorage()
    window.location.replace('/?relogin=true')
  }

  /**
   * User 정보를 전역 state로 관리
   * AccessToken 만료전 refetch 예약작업 수행
   * @param user 유지할 유저 정보 객체
   */
  const login = (user: LoginUser) => {
    setUser(user)
    setAccessTokenToStorage(user.tokenInfo)
    if (loc.pathname === '/') {
      navigate('/admin')
    }
  }

  /**
   * 로그아웃 수행
   * replace를 통해 새로고침하며 app state를 초기화 및
   * localStorage의 TokenInfo도 삭제
   * @author jeongyun
   */
  const logout = () => {
    removeAccessTokenFromStorage()
    window.location.replace('/')
  }

  const refreshTokenMutation = useMutation((token: TokenInfo['token']) =>
    api.authService.refreshToken(token)
  )

  const refreshUserMutation = useMutation(
    (tokenInfo: TokenInfo) => api.authService.tokenToUser(tokenInfo.token),
    {
      onSuccess(userInfo, tokenInfo) {
        login({ ...userInfo, tokenInfo: tokenInfo })
      },
      onError() {
        logout()
      },
    }
  )

  /**
   * AccessToken 만료 7분전 새로운 토큰을 받아오기한 예약작업 수행
   * @param token 서버로부터 받아온 AccessToken 객체
   */
  function reservationRefetchToken(token: TokenInfo) {
    const expiresIn = Number(token.expiresIn)
    const expiresInMs = expiresIn * 1000
    //원래 만료시간의 20퍼를 뺀값을 리페치 포인트로 잡는다
    const refreshPoint = expiresInMs - expiresInMs * 0.2

    if (refetchCallbackRef) clearTimeout(refetchCallbackRef)

    const timeoutRef = setTimeout(() => {
      refreshUser()
    }, refreshPoint)

    setRefetchCallbackRef(timeoutRef)
  }

  /**
   * LocalStorage의 AccessToken을 사용하여 새로운 User객체를 받아오는 함수
   * 성공할 경우 전역 State로 새로운 User객체를 유지시킨다
   * @author jeongyun
   */
  async function refreshUser() {
    const tokenInfo = getAccessTokenFromStorage()
    if (tokenInfo) {
      refreshTokenMutation.mutate(tokenInfo.token, {
        onSuccess(newTokenInfo) {
          refreshUserMutation.mutate(newTokenInfo)
          reservationRefetchToken(newTokenInfo)
        },
        onError() {
          logout()
        },
      })
      return
    }
    setUser(null)
  }

  return {
    user,
    login,
    requireReLogin,
    logout,
    isAdmin,
    isSuperAdmin,
    isSuperSuperAdmin,
    refreshUser,
    reservationRefetchToken,
    replaceRootPathCause401Error,
    replaceRootPathCause403Error,
  }
}

export default useAuth
