import { UserProfile } from '@components/shared/Icons'
import RouteLink from '@components/shared/RouteLink'
import useAuth from '@hooks/useAuth'
import { getAccessTokenFromStorage } from '@utils/storage'
import 'twin.macro'
import BreadCrumb from '../BreadCrumb'

interface Props {
  breadCrumbPath: string[]
}

const TopMenu = ({ breadCrumbPath }: Props) => {
  const { user, logout } = useAuth()
  return (
    <nav tw="flex gap-20 pr-20 py-5 border-b-1 mb-15">
      <BreadCrumb paths={breadCrumbPath} />
      <div tw="flex gap-20 ml-auto">
        <RouteLink
          tw="flex items-center gap-8"
          to={`/admin/account-management/detail?id=${user?.id}`}
        >
          <div tw="bg-gray-600 relative top-[-1px] w-21 h-21 rounded-full p-2 flex items-center justify-center">
            <UserProfile width={17} height={17} />
          </div>
          <button tw="text-black text-17 font-pretendardSB" className="button">
            {user?.nickname}
          </button>
        </RouteLink>
        <a
          href={`${process.env.REACT_APP_KTCC_GAME_CLIENT_URL}?code=${
            getAccessTokenFromStorage()?.token ?? ''
          }`}
          target={'_blank'}
          rel="noreferrer"
        >
          <button className="button base blue">메타버스</button>
        </a>
        <button onClick={logout} className="button base gray">
          로그아웃
        </button>
      </div>
    </nav>
  )
}

export default TopMenu
