import AuthChecker from '@components/auth/AuthChecker'
import UnauthorizedErrorObserver from '@components/auth/RemoteErrorObserver'
import RenderModals from '@components/shared/RenderModals'
import ErrorBoundary from '@components/suspense/ErrorBoundary'
import GlobalErrorFallback from '@components/suspense/GlobalErrorFallback'
import Router from '@routes/Router'
import '@styles/global/index.scss'
import '@styles/tailwindGlobal/index.scss'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { BrowserRouter } from 'react-router-dom'

const App = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: { retry: 1 },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthChecker />
        <ErrorBoundary
          fallback={({ error, resetBoundary }) => (
            <GlobalErrorFallback error={error} resetBoundary={resetBoundary} />
          )}
        >
          <Router />
          <RenderModals />
          <UnauthorizedErrorObserver />
        </ErrorBoundary>
      </BrowserRouter>
    </QueryClientProvider>
  )
}

export default App
