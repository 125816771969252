import { api } from '@api/index'
import DisplayStandFrom from '@components/exhibition/FormFragments/DisplayStandFrom'
import ExhibitionTitleForm from '@components/exhibition/FormFragments/ExhibitionTitleForm'
import SculptureForm from '@components/exhibition/FormFragments/SculptureForm'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import MessageModal from '@components/shared/MessageModal'
import PageLoading from '@components/shared/PageLoading'
import { MESSAGE } from '@constant/message'
import { useExhibitionFormState } from '@hooks/useExhibitionFormState'
import useModal from '@hooks/useModal'
import { useStepperState } from '@hooks/useStepperState'
import { useMutation } from '@tanstack/react-query'
import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'

const DisplayStandFormLength = 10
const SculptureFormLength = 2

const AddExhibition = () => {
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { reset: exhibitionFormReset, setFetchDataToForm } =
    useExhibitionFormState()
  const { reset: stepReset } = useStepperState()
  const loc = useLocation()
  const { mode, id } = queryString.parse(loc.search)
  const { stepper, next, prev, setStepLength } = useStepperState()

  const exhibitionInfoMutation = useMutation(
    ['exhibitionInfo'],
    (exhibitionUUID: number) =>
      api.exhibitionService.exhibitionInfo(exhibitionUUID),
    {
      onSuccess(res) {
        setFetchDataToForm(res)
      },
      async onError() {
        await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.error.badRequest.exhibition}
          </MessageModal>
        ))
        navigate('/admin/exhibition-management')
      },
    }
  )

  useEffect(() => {
    if (mode !== 'modify' || Number(id) < 0) {
      exhibitionFormReset()
      stepReset()
      return
    }
    exhibitionInfoMutation.mutate(Number(id))
    stepReset()
  }, [])

  const displayStandForms = Array.from(
    { length: DisplayStandFormLength },
    (_, idx) => (
      <DisplayStandFrom
        orderNumber={idx}
        key={`displayStand${idx}`}
        nextStep={next}
        prevStep={prev}
      />
    )
  )

  const sculptureForms = Array.from(
    { length: SculptureFormLength },
    (_, idx) => (
      <SculptureForm
        key={`sculpture${idx}`}
        orderNumber={idx}
        nextStep={next}
        prevStep={prev}
        isLast={idx === SculptureFormLength - 1}
      />
    )
  )

  const formStatComponents = [
    <ExhibitionTitleForm nextStep={next} key={uuid()} />,
    ...displayStandForms,
    ...sculptureForms,
  ]

  useEffect(() => {
    setStepLength(formStatComponents.length)
  }, [])

  return (
    <ContentWrapper breadCrumbPath={['기획전시실', '기획전 추가/수정']}>
      <CardSection className="relative flex flex-col items-center justify-center min-h-830">
        {exhibitionInfoMutation.isLoading ? (
          <PageLoading covered />
        ) : (
          formStatComponents[stepper.currentStep]
        )}
      </CardSection>
    </ContentWrapper>
  )
}

export default AddExhibition
