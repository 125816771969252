import FileInput from '@components/form/FileInput'
import MessageModal from '@components/shared/MessageModal'
import { useDisplayStandForms } from '@hooks/useDisplayStandForms'
import useModal from '@hooks/useModal'
import { deleteNotNecessaryProperty } from '@utils/deleteNotNecessaryProperty'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'
import StepDot from '../StepDot'

export type DisplayStandForm = {
  id?: number
  isValid: boolean
  number: number
  title: string
  npcGender: string
  image?: File | string
  description?: File | string
}

interface Props {
  orderNumber: number
  nextStep: Function
  prevStep: Function
}

const DisplayStandFrom = ({ nextStep, prevStep, orderNumber }: Props) => {
  const { getDisplayStandForm, setDisplayStandForm } = useDisplayStandForms()
  const { openModal } = useModal()

  const methods = useForm<DisplayStandForm>({
    mode: 'onChange',
    defaultValues: {
      ...getDisplayStandForm(orderNumber),
      number: orderNumber,
      isValid: true,
    },
  })

  const { handleSubmit, register, getValues } = methods

  const handleDisplayStandFromSubmit = (formValue: DisplayStandForm) => {
    const processedValue =
      deleteNotNecessaryProperty<DisplayStandForm>(formValue)
    setDisplayStandForm(processedValue)
    nextStep()
  }

  //이미지 파일 전체를 업로드 하지 않을시 전역상태 저장만 진행하고 페칭은 하지 않기위해 기록
  const handleDisplayStandFromSubmitError = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        <span>
          이미지를 첨부하지 않을시 {orderNumber + 1}번 전시대는 생성되지
          않습니다.
        </span>
        <br />
        <span>그래도 다음단계로 이동하시겠습니까?</span>
      </MessageModal>
    ))
    if (!modalRes) return

    const formValues = getValues()
    if (typeof formValues.image === 'string') {
      formValues.image = ''
    }
    const processedValue = deleteNotNecessaryProperty<DisplayStandForm>(
      getValues()
    )
    processedValue.isValid = false
    setDisplayStandForm(processedValue)
    nextStep()
  }

  return (
    <FormProvider<DisplayStandForm> {...methods}>
      <form
        id="display-stand-form"
        tw="w-full flex flex-col under:w-550 items-center gap-20"
        onSubmit={handleSubmit(
          handleDisplayStandFromSubmit,
          handleDisplayStandFromSubmitError
        )}
      >
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`전시대${orderNumber + 1} 제목`}
          </label>
          <input
            {...register(`title`)}
            tw="w-full flex-1"
            className="input"
            placeholder="전시대 제목을 입력해 주세요."
          ></input>
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`전시대${orderNumber + 1} 이미지`}
          </label>
          <FileInput
            tw="w-full flex-1"
            id={`image`}
            placeholder="이미지를 선택해 주세요."
            accept="image/png"
            fileExtension={['.jpg']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`전시대${orderNumber + 1} 해설`}
          </label>
          <FileInput
            validation={false}
            tw="w-full flex-1"
            id={`description`}
            placeholder="해설 음성을 선택해 주세요."
            accept="audio/mp3"
            fileExtension={['.mp3']}
          />
        </div>
        <div tw="flex gap-18 items-center">
          <label tw="rounded-md basis-120 h-35 flex items-center justify-end">
            {`전시대${orderNumber + 1} NPC`}
          </label>
          <select
            {...register(`npcGender`)}
            tw="py-0 w-full flex-1"
            className="input"
          >
            <option value="MALE">남</option>
            <option value="FEMALE">여</option>
          </select>
        </div>
      </form>
      <StepDot tw="mt-30" />
      <div tw="flex justify-center all:w-150 gap-20 mt-30">
        <button onClick={() => prevStep()} className="button base darkBrown">
          이전
        </button>
        <button className="button base lightBrown" form="display-stand-form">
          다음
        </button>
      </div>
    </FormProvider>
  )
}

export default DisplayStandFrom
