import { stepperState } from '@store/stepper'
import { useRecoilState } from 'recoil'

const useStepperState = () => {
  const [stepper, setStepper] = useRecoilState(stepperState)

  const setStepLength = (length: number) => {
    setStepper((prev) => {
      const nextState = { ...prev }
      nextState.stepLength = length
      return nextState
    })
  }

  const reset = () => {
    setStepper((prev) => {
      const nextState = { ...prev }
      nextState.currentStep = 0
      return nextState
    })
  }

  const next = () => {
    setStepper((prev) => {
      const nextState = { ...prev }
      nextState.currentStep += 1
      return nextState
    })
  }

  const prev = () => {
    setStepper((prev) => {
      const nextState = { ...prev }
      nextState.currentStep -= 1
      return nextState
    })
  }

  return { stepper, setStepper, next, prev, setStepLength, reset }
}

export { useStepperState }
