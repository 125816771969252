import { MESSAGE } from '@constant/message'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'

interface Props extends ReactSelect {
  id: string
}

const GenderSelect = ({ id, className }: Props) => {
  const { watch, register } = useFormContext()

  return (
    <select
      tw="focus:text-black"
      className={clsx(className, watch(id) === 'pending' && 'text-gray-400')}
      {...register(id, {
        validate(value) {
          return /MALE|FEMALE/g.test(value) || MESSAGE.user.require.gender
        },
      })}
    >
      <option value="pending" disabled>
        성별을 선택해 주세요.
      </option>
      <option value="MALE" tw="text-black">
        남
      </option>
      <option value="FEMALE" tw="text-black">
        여
      </option>
    </select>
  )
}

export default GenderSelect
