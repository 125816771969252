import { RightArrow } from '@components/shared/Icons'
import { Fragment } from 'react'
import 'twin.macro'

interface Props {
  paths: string[]
}

const BreadCrumb = ({ paths }: Props) => {
  return (
    <div tw="rounded-md px-20 flex gap-8 text-lg items-center font-pretendardEB text-c-brown-300">
      {paths.map((path, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && (
            <div>
              <RightArrow width={13} height={13} />
            </div>
          )}
          <div>{path}</div>
        </Fragment>
      ))}
    </div>
  )
}

export default BreadCrumb
