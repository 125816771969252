import { api } from '@api/index'
import GuestBookItem from '@components/guestBook/GuestBookItem'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import PageLoading from '@components/shared/PageLoading'
import PaginationBar from '@components/shared/PaginationBar'
import SearchBar from '@components/shared/SearchBar'
import { useQuery } from '@tanstack/react-query'
import { useState } from 'react'
import usePagination from 'react-use-pagination-hook'
import 'twin.macro'

const TABLE_ROW_SIZE = 2
const GuestBookManagement = () => {
  const [searchKey, setSearchKey] = useState('')

  const paginationMethods = usePagination({ numOfPage: 5 })
  const { setTotalPage, currentPage } = paginationMethods

  const { data: guestBookList, isLoading: isGuestBookListLoading } = useQuery(
    ['guestBookList', currentPage, searchKey],
    () =>
      api.guestBookService.getGuestBookList(
        currentPage - 1,
        TABLE_ROW_SIZE,
        searchKey
      ),
    {
      onSuccess(paginationResponse) {
        setTotalPage(paginationResponse.totalPages)
      },
    }
  )

  return (
    <ContentWrapper breadCrumbPath={['방명록']}>
      <CardSection tw="pb-0">
        <div tw="flex items-center mt-15 gap-10">
          <SearchBar<GuestBook>
            tw="w-350"
            searchKey="content"
            placeholder="방명록 내용 검색"
            onSearch={setSearchKey}
          />
        </div>
        <div tw="h-695 w-full mt-25 flex flex-col">
          {isGuestBookListLoading && <PageLoading />}
          {!isGuestBookListLoading && (
            <>
              {!guestBookList?.content.length && (
                <div tw="w-full pt-300 flex justify-center items-center">
                  방명록이 없습니다.
                </div>
              )}
              {guestBookList?.content.map((guestBookInfo) => (
                <GuestBookItem key={guestBookInfo.id} {...guestBookInfo} />
              ))}
              {guestBookList?.content.length === 1 && (
                <div tw="border-t-2 py-15 border-gray-300 w-full h-full"></div>
              )}
            </>
          )}
        </div>
      </CardSection>
      <PaginationBar tw="mt-17" {...paginationMethods} />
    </ContentWrapper>
  )
}

export default GuestBookManagement
