import { LOCAL_STORAGE_KEY } from '@constant/localStroageKey'
import {
  getValueFromLocalStorage,
  removeValueFromLocalStorage,
  setValueToLocalStorage,
} from './localStorage'

export const setAccessTokenToStorage = (token: TokenInfo) => {
  setValueToLocalStorage(LOCAL_STORAGE_KEY.ACCESS_TOKEN, token)
}

export const getAccessTokenFromStorage = (): TokenInfo | undefined =>
  getValueFromLocalStorage(LOCAL_STORAGE_KEY.ACCESS_TOKEN) as TokenInfo &
    undefined

export const removeAccessTokenFromStorage = () =>
  removeValueFromLocalStorage(LOCAL_STORAGE_KEY.ACCESS_TOKEN)
