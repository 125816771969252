import { modalsState } from '@store/modals'
import { ReactElement } from 'react'
import { useRecoilState } from 'recoil'

type OnClose<T> = (ret: T) => void

// Delay 기준 확인 -> src/components/shared/renderModal/index.scss의 animation delay값
const MODAL_CLOSE_DELAY = 190

const useModal = () => {
  const [_, setModals] = useRecoilState(modalsState)

  const execModalCloseAnimation = (delay = MODAL_CLOSE_DELAY) => {
    return new Promise((resolve) => {
      setModals((modals) => {
        const length = modals.length
        if (length) {
          const targetModal = modals[length - 1]
          return [
            // 모달관리자의 state를 변경시켜 RenderModals 컴포넌트에서 애니메이션이 적용될 수 있도록 함
            ...modals.slice(0, length - 1),
            { element: targetModal.element, state: 'close' },
          ]
        }
        return [...modals]
      })
      //애니메이션이 끝나는 타이밍에 맞춰 resolve
      setTimeout(resolve, delay)
    })
  }

  const closeModal = () => {
    setModals((modals) => {
      const currentModals = [...modals]
      currentModals.pop()
      return currentModals
    })
  }

  const closeAllModals = async () => {
    const modalsCount =
      document.getElementById('modal-root')?.children.length ?? 0
    for (let i = 0; i < modalsCount; i++) {
      await execModalCloseAnimation().then(closeModal)
    }
    return true
  }

  const openModal = <Type = boolean>(
    elementFactory: (
      onClose: OnClose<Type>,
      closeAllModal: OnClose<Type>
    ) => ReactElement
  ): Promise<Type> => {
    return new Promise((resolve) => {
      const onClose: OnClose<Type> = (ret) => {
        execModalCloseAnimation()
          .then(closeModal)
          .then(() => resolve(ret))
      }

      const closeAllModalsWrapper: OnClose<Type> = (ret) => {
        closeAllModals()
        resolve(ret)
      }

      const element = elementFactory(onClose, closeAllModalsWrapper)
      if (!element.props.onClose) {
        throw new Error(
          'Element passed to openModal must have optional onClose method as props'
        )
      }
      setModals((prev) => [...prev, { element, state: 'open' }])
    })
  }

  return { openModal, closeAllModals }
}

export default useModal
