import { api } from '@api/index'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { dateFormat } from '@utils/dateFormat'
import moment from 'moment'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import 'twin.macro'

interface Props extends GuestBook {}

interface GuestBookModifyForm {
  content: string
}

const GuestBookItem = ({
  createdAt,
  author,
  content,
  id,
  exhibition,
}: Props) => {
  const [isModifyMode, setIsModifyMode] = useState(false)
  const { openModal } = useModal()
  const queryClient = useQueryClient()
  const formMethods = useForm<GuestBookModifyForm>({
    mode: 'onChange',
    defaultValues: { content },
  })
  const { register, handleSubmit } = formMethods

  const deleteGuestBookMutation = useMutation(
    ['deleteGuestBook'],
    (id: number) => api.guestBookService.deleteGuestBook(id),
    {
      onSuccess() {
        queryClient.invalidateQueries(['guestBookList'])
      },
    }
  )

  const updateGuestBookMutation = useMutation(
    ['updateGuestBook'],
    ({ content }: GuestBookModifyForm) =>
      api.guestBookService.updateGuestBook(id, content),
    {
      onSuccess() {
        queryClient.invalidateQueries(['guestBookList'])
      },
    }
  )

  const handleGuestBookModifySubmit = async (
    formValue: GuestBookModifyForm
  ) => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.updateGuestBook}
      </MessageModal>
    ))
    if (modalRes)
      updateGuestBookMutation.mutate(formValue, {
        onSuccess() {
          openModal((onClose) => (
            <MessageModal nonRes onClose={onClose}>
              {MESSAGE.success.updated.guestBook}
            </MessageModal>
          ))
          setIsModifyMode(false)
        },
      })
  }

  const handleGuestBookDelete = async (guestBookUUID: number) => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.deleteGuestBook}
      </MessageModal>
    ))
    if (modalRes)
      deleteGuestBookMutation.mutate(guestBookUUID, {
        onSuccess() {
          openModal((onClose) => (
            <MessageModal nonRes onClose={onClose}>
              {MESSAGE.success.deleted.guestBook}
            </MessageModal>
          ))
        },
      })
  }

  return (
    <form
      id="guest-book-form"
      onSubmit={handleSubmit(handleGuestBookModifySubmit)}
      tw="border-t-2 py-15 border-gray-300 w-full h-full flex justify-center items-center"
    >
      <div tw="h-full w-540">
        <div tw="relative flex gap-15 items-center">
          <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
            작성자
          </label>
          <button
            onClick={
              isModifyMode
                ? undefined
                : (e) => {
                    e.preventDefault()
                    setIsModifyMode(true)
                  }
            }
            type={isModifyMode ? 'submit' : 'button'}
            className="absolute right-[-70px] button base lightBrown"
          >
            {isModifyMode ? '확인' : '수정'}
          </button>
          <button
            onClick={() => setTimeout(() => handleGuestBookDelete(id), 5)}
            type="button"
            className="absolute button base right-[-140px] darkBrown"
          >
            삭제
          </button>
          <div tw="flex-1" className="input view">
            {author.nickname}
          </div>
        </div>
        <div tw="relative flex gap-15 mt-10 items-center">
          <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
            작성일자
          </label>
          <div tw="flex-1" className="input view">
            {dateFormat.fullKebab(moment(createdAt))}
          </div>
        </div>
        <div tw="relative flex gap-15 mt-10 items-center">
          <label tw="absolute w-100 flex justify-end left-[-120px] font-pretendardSB text-c-gray-300">
            기획전
          </label>
          <div tw="flex-1 !truncate" className="input view">
            {exhibition.name}
          </div>
        </div>
        <div tw="relative flex gap-15 mt-10 items-center">
          <label tw="absolute w-100 flex justify-end left-[-120px] top-0 font-pretendardSB text-c-gray-300">
            게시글
          </label>
          {isModifyMode ? (
            <textarea
              {...register('content')}
              id="age"
              tw="flex-1 resize-none py-5 px-10 h-160 border-1"
              className="input"
            />
          ) : (
            <div
              tw="whitespace-pre-wrap flex-1 py-5 px-10 border-1 !block h-160 overflow-auto"
              className="input view"
            >
              {content}
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default GuestBookItem
