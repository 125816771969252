import { modalsState } from '@store/modals'
import clsx from 'clsx'
import { useRecoilValue } from 'recoil'
import 'twin.macro'
import PortalToElement from '../PortalToElement'
import './index.scss'

const RenderModals = () => {
  const modals = useRecoilValue(modalsState)

  return (
    <PortalToElement
      container={document.querySelector('#modal-root') ?? document.body}
    >
      {modals.map(({ element, state }, idx) => (
        <div
          key={idx}
          tw="fixed flex items-center justify-center z-50 top-0 left-0 right-0 bottom-0"
          className={clsx(!idx && 'modal-background')}
        >
          <div
            className={clsx(
              state === 'open'
                ? 'modal-slideDown-animation'
                : 'modal-slideUp-animation'
            )}
          >
            {element}
          </div>
        </div>
      ))}
    </PortalToElement>
  )
}

export default RenderModals
