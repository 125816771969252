interface Props extends React.HTMLAttributes<HTMLDivElement> {}
import 'twin.macro'

const CardSection = ({ className, ...rest }: Props) => {
  return (
    <section
      {...rest}
      className={
        'w-full p-10 bg-white rounded-md px-25 shadowBase ' + className
      }
    ></section>
  )
}

export default CardSection
