import ReactApexChart from 'react-apexcharts'

interface Props {
  data: GenderMetric[]
}
const GenderRatioChart = ({ data }: Props) => {
  const fitToData = Array(2)
    .fill(0)
    .map((_, idx) => data.find(({ gender }) => gender === idx)?.count ?? 0)
  const seriesData: ApexAxisChartSeries | ApexNonAxisChartSeries = fitToData

  const chartOptions: ApexCharts.ApexOptions = {
    colors: ['#A57044', '#633A12'],
    chart: {
      type: 'donut',
      fontFamily: '-apple-system, Pretendard, sans-serif',
    },
    labels: ['남', '여'],
    plotOptions: {
      pie: {
        donut: {
          size: '47%',
        },
      },
    },
    dataLabels: {
      formatter(_, opts) {
        return seriesData[opts.seriesIndex]
      },
      enabled: true,
      textAnchor: 'middle',
      offsetY: -25,
      style: {
        colors: ['#F5ECDE'],
        fontSize: '12px',
        fontFamily: '-apple-system, PretendardSB, sans-serif',
        fontWeight: 'normal',
      },
      dropShadow: {
        enabled: false,
      },
      background: {
        enabled: true,
        foreColor: '#000000',
        padding: 4,
        borderRadius: 8,
        borderWidth: 1,
        opacity: 0.9,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      fontSize: '15px',
      fontFamily: '-apple-system, PretendardSB, sans-serif',
      markers: {
        offsetX: -5,
        offsetY: 2,
        width: 15,
        height: 15,
      },
    },
  }
  return (
    <ReactApexChart
      width={320}
      options={chartOptions}
      series={seriesData}
      type="donut"
    />
  )
}

export default GenderRatioChart
