import { useNavigate } from 'react-router-dom'
interface Props extends ReactButton {}

const BackSpaceButton = ({ onClick, children, ...rest }: Props) => {
  const navigate = useNavigate()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onClick?.(event)
    navigate(-1)
  }

  return (
    <button onClick={handleClick} {...rest}>
      {children}
    </button>
  )
}

export default BackSpaceButton
