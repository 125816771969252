import {
  ChangeEvent,
  Dispatch,
  HTMLAttributes,
  SetStateAction,
  useState,
} from 'react'
import 'twin.macro'

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  searchKey: keyof T
  onSearch: Dispatch<SetStateAction<string>>
  placeholder: string
}

const SearchBar = <T,>({
  searchKey,
  onSearch,
  placeholder,
  ...rest
}: Props<T>) => {
  const [searchKeyword, setSearchKeyword] = useState('')

  const handleSearchClick = () => {
    if (searchKeyword.length)
      onSearch(`${searchKey as string},${searchKeyword}`)
    else onSearch(``)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchKeyword(event.target.value)
  }
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSearchClick()
    }
  }

  return (
    <div {...rest} tw="flex gap-10">
      <input
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        value={searchKeyword}
        tw="w-full h-35"
        className="input"
        placeholder={placeholder}
      />
      <button
        onClick={handleSearchClick}
        className="button base lightGray"
        tw="w-120"
      >
        검색
      </button>
    </div>
  )
}

export default SearchBar
