import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { USER_ROLE } from '@constant/user'
import { AccountManagementService } from './accountService'

const getAuthoritiesArray = (authorities: USER_ROLE): USER_ROLE[] => {
  if (authorities === 'ROLE_USER') return ['ROLE_USER']
  if (authorities === 'ROLE_ADMIN') return ['ROLE_USER', 'ROLE_ADMIN']
  if (authorities === 'ROLE_SUPER_ADMIN')
    return ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN']
  return []
}

export const accountManagementRemote = (): AccountManagementService => ({
  async getAccountList(listMode, page, size, search) {
    try {
      const response = await baseAPI.get({
        url: `/admin${listMode}`,
        params: { page, size, search, sort: 'id,desc' },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getAccountDetail(userUUID: number) {
    try {
      const response = await baseAPI.get({
        url: `/admin/user/${userUUID}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async blockAccount(userUUID: number) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/user/${userUUID}/block`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async unblockAccount(userUUID: number) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/user/${userUUID}/unblock`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async deleteAccount(userUUID: number) {
    try {
      const response = await baseAPI.delete({
        url: `/admin/user/${userUUID}`,
      })
      return response.data
    } catch (error: unknown) {
      // @ts-ignore
      alert(error?.response?.data)
      throwRemoteError(error)
    }
  },
  async updateAccount(id, gender, age, authorities, nickname) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/user/${id}`,
        data: {
          nickname,
          gender,
          age,
          authorities: getAuthoritiesArray(authorities),
        },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async createAdmin(
    username,
    password,
    confirmPassword,
    nickname,
    gender,
    age,
    authorities
  ) {
    try {
      const response = await baseAPI.post({
        url: `/admin/user`,
        data: {
          username,
          password,
          confirmPassword,
          nickname,
          gender,
          age,
          authorities: getAuthoritiesArray(authorities),
        },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async changePassword(id, newPassword, confirmPassword) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/user/${id}/password`,
        data: {
          newPassword,
          confirmPassword,
        },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
