import { FILE_ACCEPT } from '@constant/fileAccept'
import { MESSAGE } from '@constant/message'
import { fileSizeToMb } from '@utils/fileUtils'
import classnames from 'classnames'
import { useCallback } from 'react'
import { Accept, useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'

const FileMaxSize = 500

interface Props extends ReactInput {
  id: string
  placeholder: string
  accept: FILE_ACCEPT
  fileExtension?: string[]
  validation?: boolean
}

const FileInput = ({
  id,
  className,
  placeholder,
  accept,
  validation = true,
  fileExtension,
}: Props) => {
  const { watch, register, setValue } = useFormContext()

  const hasFile = !!watch(id)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    const fileSizeMb = fileSizeToMb(file)
    if (fileSizeMb <= FileMaxSize) {
      setValue(id, acceptedFiles[0])
      return
    }
  }, [])

  const acceptRule: Accept = {}
  acceptRule[accept] = fileExtension ?? ['.jpeg']

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptRule,
  })

  const renderFileName = () => {
    const fileOrFileSrc = watch(id)
    if (!fileOrFileSrc) return placeholder
    if (typeof fileOrFileSrc === 'string') {
      const decode = decodeURIComponent(fileOrFileSrc)
      return decode.substring(decode.lastIndexOf('/') + 1)
    }
    if (typeof fileOrFileSrc === 'object') {
      return fileOrFileSrc.name
    }
    return placeholder
  }

  return (
    <div
      {...getRootProps()}
      className={classnames('container', className)}
      tw="flex gap-10 relative truncate"
    >
      <div
        className={classnames('input', !hasFile && 'text-gray-400')}
        tw="flex-1 flex items-center truncate cursor-pointer"
      >
        <div tw="flex-auto min-w-0 truncate">{renderFileName()}</div>
      </div>
      <label
        htmlFor={id}
        tw="cursor-pointer basis-80 w-80 flex items-center justify-center"
        className="button lightGray"
      >
        파일찾기
      </label>
      <input
        {...register(id, {
          required: validation ? MESSAGE.file.require.file : false,
        })}
        className="w-0 h-0"
      />
      <input {...getInputProps()} className="w-0 h-0" />
    </div>
  )
}

export default FileInput
