import { KTCCMainIcon } from '@components/shared/Icons'
import { Link } from 'react-router-dom'
import 'twin.macro'

const _404Page = () => {
  return (
    <section tw="w-full h-full flex flex-col items-center justify-center">
      <div tw="mb-30">
        <KTCCMainIcon width={200} height={131} />
      </div>
      <h2 tw="mb-20 text-19 font-pretendardSB">
        죄송합니다 요청하신 페이지를 찾을 수 없습니다.
      </h2>
      <div>방문하시려는 페이지의 주소가 잘못 입력되었거나,</div>
      <div tw="mb-10">
        페이지의 주소가 변경 혹은 삭제되어 요청하신 페이지를 찾을 수 없습니다.
      </div>
      <div tw="mb-30">
        입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
      </div>
      <Link to={'/'}>
        <button tw="w-300 h-40" className="base button lightBrown">
          메인으로 돌아가기
        </button>
      </Link>
    </section>
  )
}

export default _404Page
