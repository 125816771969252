import { MESSAGE } from '@constant/message'
import clsx from 'clsx'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'

interface Props extends ReactSelect {
  id: string
}

const options = Array(6)
  .fill(0)
  .map((_, i) => {
    const value = (i + 1) * 10
    if (i === 5) return { value, name: `${value}대 이상` }
    return { value, name: `${value}대` }
  })

const AgeSelect = ({ id, className }: Props) => {
  const { watch, register } = useFormContext()

  return (
    <select
      tw="focus:text-black"
      className={clsx(className, watch(id) === 'pending' && 'text-gray-400')}
      {...register(id, {
        validate(value) {
          const regexp = new RegExp(
            options
              .map(({ value }, idx) =>
                idx === 5 ? `${value}-` : `${value}-${value + 9}`
              )
              .join('|'),
            'g'
          )
          return regexp.test(value) || MESSAGE.user.require.age
        },
      })}
    >
      <option value="pending" disabled>
        나이를 선택해 주세요.
      </option>
      {options.map(({ name, value }, idx) => (
        <option
          key={value}
          value={idx === 5 ? `${value}-` : `${value}-${value + 9}`}
        >
          {name}
        </option>
      ))}
    </select>
  )
}

export default AgeSelect
