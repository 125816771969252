import 'twin.macro'
import style from './index.module.scss'

interface Props {
  size?: number
}

const LoadingSpinner = ({ size }: Props) => {
  const spinnerSize = {
    width: `${size ?? 25}px`,
    height: `${size ?? 25}px`,
  }
  return (
    <span
      style={spinnerSize}
      tw="border-b-gray-400 border-4"
      className={style.loading}
      role="status"
    ></span>
  )
}

export default LoadingSpinner
