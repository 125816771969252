import { RECOIL_KEY } from '@constant/recoilKey'
import { ReactElement } from 'react'
import { atom } from 'recoil'

interface Modal {
  state: 'open' | 'close'
  element: ReactElement
}

const defaultModalsValue: Modal[] = []

export const modalsState = atom<Modal[]>({
  key: RECOIL_KEY.MODAL,
  default: defaultModalsValue,
})
