import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { EVENT_BUS_KEY } from '@constant/eventBusKey'
import eventBus from '@utils/eventBus'
import objToFormData from '@utils/objToFormData'
import { ExhibitionService } from './exhibitionService'

export const exhibitionServiceRemote = (): ExhibitionService => ({
  async exhibitionList(page, size, search) {
    try {
      const response = await baseAPI.get({
        url: `/admin/exhibition`,
        params: { page, size, search, sort: 'id,desc' },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async createExhibitionRoot(name) {
    try {
      const response = await baseAPI.post({
        url: `/admin/exhibition`,
        data: { name },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async createDisplayStandToExhibitionRoot(exhibitionId, displayStandForm) {
    const formData = objToFormData({ exhibitionId, ...displayStandForm })
    try {
      const response = await baseAPI.post({
        url: `/admin/displaystand`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async modifyExhibitionName(exhibitionId: number, exhibitionName: string) {
    try {
      await baseAPI.patch({
        url: `/admin/exhibition/${exhibitionId}`,
        data: {
          name: exhibitionName,
        },
      })
    } catch (error) {
      throwRemoteError(error)
    }
    return { id: exhibitionId }
  },
  async createSculptureToExhibitionRoot(exhibitionId, sculptureForm) {
    const formData = objToFormData({ exhibitionId, ...sculptureForm })
    formData.get('number')
    try {
      const response = await baseAPI.post({
        url: `/admin/sculpture`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async modifyDisplayStandToExhibitionRoot(displayStandForm) {
    const formData = objToFormData({ ...displayStandForm })
    try {
      const response = await baseAPI.patch({
        url: `/admin/displaystand`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async modifySculptureToExhibitionRoot(sculptureForm) {
    const formData = objToFormData({ ...sculptureForm })
    formData.get('number')
    try {
      const response = await baseAPI.patch({
        url: `/admin/sculpture`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async deleteExhibition(exhibitionUUID) {
    try {
      const response = await baseAPI.delete({
        url: `/admin/exhibition/${exhibitionUUID}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async createFinalExhibition(exhibitionFormState) {
    let exhibitionIdForCatch: number = -1
    try {
      const exhibitionRoot = exhibitionFormState.exhibitionId
        ? await this.modifyExhibitionName(
            exhibitionFormState.exhibitionId,
            exhibitionFormState.exhibitionName
          )
        : await this.createExhibitionRoot(exhibitionFormState.exhibitionName)
      exhibitionIdForCatch = exhibitionRoot.id
      const validDisplayStand =
        exhibitionFormState.displayStandFormValues.filter(
          ({ isValid }) => isValid
        )
      const displayStandFetches = validDisplayStand.map((displayStandForm) => {
        if (displayStandForm.id) {
          if (typeof displayStandForm.image === 'string') {
            delete displayStandForm['image']
          }
          if (typeof displayStandForm.description === 'string') {
            delete displayStandForm['description']
          }
          return () => this.modifyDisplayStandToExhibitionRoot(displayStandForm)
        }
        return () =>
          this.createDisplayStandToExhibitionRoot(
            exhibitionRoot.id,
            displayStandForm
          )
      })
      const validSculptures = exhibitionFormState.sculptureFormValues.filter(
        ({ isValid }) => isValid
      )
      const sculptureFetches = validSculptures.map((sculptureForm) => {
        if (sculptureForm.id) {
          if (typeof sculptureForm.frontSide === 'string') {
            delete sculptureForm['frontSide']
          }
          if (typeof sculptureForm.leftSide === 'string') {
            delete sculptureForm['leftSide']
          }
          if (typeof sculptureForm.backSide === 'string') {
            delete sculptureForm['backSide']
          }
          if (typeof sculptureForm.rightSide === 'string') {
            delete sculptureForm['rightSide']
          }
          if (typeof sculptureForm.leftHalfSide === 'string') {
            delete sculptureForm['leftHalfSide']
          }
          if (typeof sculptureForm.rightHalfSide === 'string') {
            delete sculptureForm['rightHalfSide']
          }
          if (typeof sculptureForm.description === 'string') {
            delete sculptureForm['description']
          }
          return () => this.modifySculptureToExhibitionRoot(sculptureForm)
        }
        return () =>
          this.createSculptureToExhibitionRoot(exhibitionRoot.id, sculptureForm)
      })

      eventBus.dispatch(EVENT_BUS_KEY.EXHIBITION_FORMS_LENGTH, {
        displayStandFetchesNumbers: validDisplayStand.map(
          ({ number }) => number
        ),
        sculptureFetchesNumbers: validSculptures.map(({ number }) => number),
      })

      const allForms = [...displayStandFetches, ...sculptureFetches]
      for (let i = 0; i < allForms.length; i++) {
        await allForms[i]()
        eventBus.dispatch(EVENT_BUS_KEY.EXHIBITION_SUCCESS_INDEX, {
          index: i,
        })
      }

      return true
    } catch (error) {
      if (exhibitionIdForCatch !== -1) {
        this.deleteExhibition(exhibitionIdForCatch)
      }
      throwRemoteError(error)
    }
  },
  async exhibitionInfo(exhibitionUUID) {
    try {
      const response = await baseAPI.get({
        url: `/exhibition/${exhibitionUUID}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async exhibitedExhibition() {
    try {
      const response = await baseAPI.get({
        url: `/exhibition/exhibited`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async exhibitExhibition(exhibitionUUID) {
    try {
      const response = await baseAPI.patch({
        url: `/admin/exhibition/${exhibitionUUID}/exhibit`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
