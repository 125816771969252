import LoadingSpinner from '@components/shared/LoadingSpinner'
import { MUTATION_QUERY_KEY } from '@constant/reactQueryKey'
import { useIsMutating } from '@tanstack/react-query'
import { ReactNode } from 'react'

interface Props extends ReactButton {
  children: ReactNode
  loadingKey: MUTATION_QUERY_KEY
  loadingSize?: number
}

const LoadableButton = ({
  loadingKey,
  loadingSize,
  children,
  className,
  ...rest
}: Props) => {
  const isMutating = useIsMutating({ mutationKey: [loadingKey] })

  return (
    <button
      disabled={!!isMutating}
      {...rest}
      className={'flex items-center justify-center ' + className}
    >
      {isMutating ? <LoadingSpinner size={loadingSize} /> : children}
    </button>
  )
}

export default LoadableButton
