import moment from 'moment'
import ReactApexChart from 'react-apexcharts'

interface Props {
  data: VisitedMetric[]
  baseDate: moment.Moment
}

const VisitMetricYearChart = ({ data, baseDate }: Props) => {
  const fitToData = Array(12)
    .fill(0)
    .map(
      (_, idx) =>
        data.find(({ date }) => moment(date).month() === idx)?.count ?? 0
    )
  const seriesData: ApexAxisChartSeries | ApexNonAxisChartSeries = [
    {
      name: '방문자 집계',
      data: fitToData,
    },
  ]

  const chartOptions: ApexCharts.ApexOptions = {
    fill: {
      colors: ['#9F9585'],
      opacity: 1,
      type: 'solid',
    },
    chart: {
      animations: {
        animateGradually: {
          enabled: false,
        },
      },
      toolbar: {
        show: false,
      },
      type: 'bar',
      height: '100%',
      fontFamily: '-apple-system, Pretendard, sans-serif',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '27%',
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: true,
      textAnchor: 'middle',
      offsetY: -25,
      style: {
        colors: ['#F5ECDE'],
        fontSize: '11px',
        fontFamily: '-apple-system, Pretendard, sans-serif',
        fontWeight: 'normal',
      },
      background: {
        enabled: true,
        foreColor: '#000000',
        padding: 7,
        borderRadius: 8,
        borderWidth: 1,
        opacity: 0.9,
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: Array.from({ length: 12 }, (_, i) => `${i + 1} 월`),
    },
    yaxis: { show: false },
  }

  return (
    <ReactApexChart
      key={baseDate.toISOString()}
      options={chartOptions}
      series={seriesData}
      type="bar"
      height={275}
      width={1225}
    />
  )
}

export default VisitMetricYearChart
