import { EVENT_BUS_KEY } from '@constant/eventBusKey'
import eventBus from '@utils/eventBus'
import { useEffect, useState } from 'react'
import { BsCheckLg } from 'react-icons/bs'
import 'twin.macro'
import LoadingSpinner from '../LoadingSpinner'

interface Props {
  onClose: Function
}

const LoadingModal = (_: Props) => {
  const [successIndex, setSuccessIndex] = useState<Set<number>>(new Set([]))
  const [displayStandFetchesNumbers, setDisplayStandFetchesNumbers] = useState<
    number[]
  >([])
  const [sculptureFetchesNumbers, setSculptureFetchesNumbers] = useState<
    number[]
  >([])

  useEffect(() => {
    const exhibitionFormsLengthCallback = (e: CustomEvent) => {
      setSculptureFetchesNumbers(e.detail.sculptureFetchesNumbers)
      setDisplayStandFetchesNumbers(e.detail.displayStandFetchesNumbers)
    }
    const exhibitionFormSuccessCallback = (e: CustomEvent) => {
      setSuccessIndex((prev) => new Set(prev.add(Number(e.detail.index ?? -1))))
    }
    eventBus.on(
      EVENT_BUS_KEY.EXHIBITION_FORMS_LENGTH,
      exhibitionFormsLengthCallback
    )
    eventBus.on(
      EVENT_BUS_KEY.EXHIBITION_SUCCESS_INDEX,
      exhibitionFormSuccessCallback
    )
  }, [])

  return (
    <div
      tw="w-430 min-h-200 bg-white  rounded-md p-20 flex flex-col items-center justify-center"
      className="shadowBase"
    >
      <div tw="mt-10 text-18 font-pretendardSB">업로드 중입니다...</div>
      <div tw="mt-10 mb-10 font-pretendardSB">
        최대 3 ~ 4 분정도 소요 될 수 있습니다.
      </div>
      {displayStandFetchesNumbers.map((number, idx) => (
        <div key={idx} tw="flex gap-10 items-center h-40">
          <div tw="w-130">전시대 {number + 1}</div>
          <div>
            {successIndex.has(idx) ? (
              <BsCheckLg size={25} color="#1cc422" />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      ))}
      {sculptureFetchesNumbers.map((number, idx) => (
        <div key={idx} tw="flex gap-10 items-center h-40">
          <div tw="w-130">조형물 {number + 1}</div>
          <div>
            {successIndex.has(idx + displayStandFetchesNumbers.length) ? (
              <BsCheckLg size={25} color="#1cc422" />
            ) : (
              <LoadingSpinner />
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export default LoadingModal
