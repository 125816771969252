import { api } from '@api/index'
import FormError from '@components/form/FormError'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation } from '@tanstack/react-query'
import { useDropzone } from 'react-dropzone'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { fileSizeToMb } from '@utils/fileUtils'
import { useCallback } from 'react'
import 'twin.macro'

interface AddDocsForm {
  title: string
  file: File
}

const FileMaxSize = 500

const AddDocs = () => {
  const { openModal } = useModal()
  const navigate = useNavigate()
  const methods = useForm<AddDocsForm>()
  const { register, handleSubmit, setValue, watch } = methods

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0]
    const fileSizeMb = fileSizeToMb(file)
    if (fileSizeMb <= FileMaxSize) {
      setValue('file', acceptedFiles[0])
      return
    }
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { 'application/pdf': ['.pdf'] },
  })

  const addDocsMutation = useMutation(
    ['addDocs'],
    ({ title, file }: AddDocsForm) => api.mediaService.addDocs(title, file),
    {
      async onSuccess() {
        await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.created.docs}
          </MessageModal>
        ))
        navigate('/admin/media-management?init_type=file')
      },
    }
  )

  const handleSubmitAddPdfForm = (formValue: AddDocsForm) => {
    addDocsMutation.mutate(formValue)
  }

  return (
    <ContentWrapper breadCrumbPath={['미디어', '문서 추가']}>
      <CardSection className="flex flex-col items-center justify-center min-h-830">
        <FormProvider {...methods}>
          <form
            id="video-add-form"
            tw="w-full flex flex-col under:w-550 items-center mr-27"
            onSubmit={handleSubmit(handleSubmitAddPdfForm)}
          >
            <div tw="flex gap-20 items-center">
              <label tw="font-pretendardSB rounded-md basis-120 h-35 flex items-center justify-end">
                제목
              </label>
              <input
                {...register('title', {
                  required: MESSAGE.media.require.docTitle,
                })}
                tw="w-full"
                className="input base"
                placeholder="문서 제목을 입력해 주세요."
                type="text"
              />
            </div>
            <FormError<AddDocsForm> tw="ml-230" targetInputId={'title'} />
            <div tw="flex gap-20 ">
              <label tw="font-pretendardSB rounded-md basis-120 h-35 flex items-center justify-end">
                파일첨부
              </label>
              <div
                {...getRootProps()}
                tw="bg-[#f5f5f5] px-30 cursor-grabbing text-gray-600 w-full h-200 rounded-md flex flex-col justify-center items-center text-15"
              >
                <input
                  {...register('file', {
                    required: MESSAGE.file.require.file,
                  })}
                  className="w-0 h-0"
                />
                <input {...getInputProps()} className="w-0 h-0" />
                <span>{`이곳에 파일을 드래그하세요. 또는 파일선택 (${fileSizeToMb(
                  watch('file')
                )}MB/${FileMaxSize}MB)`}</span>
                {watch('file') && (
                  <span tw="text-c-brown-300 font-pretendardSB">
                    선택된 파일 : {watch('file').name}
                  </span>
                )}
              </div>
            </div>
            <FormError<AddDocsForm> tw="ml-230" targetInputId={'file'} />
          </form>
        </FormProvider>
        <div tw="h-40 flex justify-center all:w-165 gap-20 all:font-pretendardM all:tracking-wider">
          <LoadableButton
            className="button base lightBrown"
            form="video-add-form"
            loadingKey="addDocs"
          >
            추가
          </LoadableButton>
          <BackSpaceButton className="button base darkBrown">
            취소
          </BackSpaceButton>
        </div>
      </CardSection>
    </ContentWrapper>
  )
}

export default AddDocs
