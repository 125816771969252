import { api } from '@api/index'
import { useQuery } from '@tanstack/react-query'
import 'twin.macro'

const RAUCards = () => {
  const { data: RAU } = useQuery(['rau'], api.statisticsService.getRAU)
  return (
    <section tw="flex gap-20 w-full mt-20 under:w-full under:h-170 under:font-pretendardSB under:bg-white under:rounded-md">
      <div className="flex flex-col items-center justify-center shadowBase">
        <div tw="pb-10 pt-5 border-b-1 border-gray-300 w-200 text-18 text-center">
          일일 신규 가입자 수
        </div>
        <div tw="pt-5 text-30 text-orange-400">{RAU?.dru}</div>
      </div>
      <div className="flex flex-col items-center justify-center shadowBase">
        <div tw="pb-10 pt-5 border-b-1 border-gray-300 w-200 text-18 text-center">
          일일 활성 유저수
        </div>
        <div tw="pt-5 text-30 text-orange-400">{RAU?.dau}</div>
      </div>
      <div className="flex flex-col items-center justify-center shadowBase">
        <div tw="pb-10 pt-5 border-b-1 border-gray-300 w-200 text-18 text-center">
          주간 활성 유저수
        </div>
        <div tw="pt-5 text-30 text-orange-400">{RAU?.wau}</div>
      </div>
      <div className="flex flex-col items-center justify-center shadowBase">
        <div tw="pb-10 pt-5 border-b-1 border-gray-300 w-200 text-18 text-center">
          월간 활성 유저수
        </div>
        <div tw="pt-5 text-30 text-orange-400">{RAU?.mau}</div>
      </div>
    </section>
  )
}

export default RAUCards
