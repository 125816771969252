import { MESSAGE } from '@constant/message'
import { REGEXPS } from '@constant/regexps'
import { sliceToMaxLength } from '@utils/sliceToMaxLength'
import { useFormContext } from 'react-hook-form'
import 'twin.macro'

interface Props extends ReactInput {
  id: string
}

const IdInput = ({ id, className }: Props) => {
  const { register } = useFormContext()
  return (
    <input
      id={id}
      {...register(id, {
        required: MESSAGE.user.require.id,
        pattern: {
          value: REGEXPS.user.id,
          message: MESSAGE.user.invalid.id,
        },
      })}
      onInput={sliceToMaxLength}
      maxLength={20}
      placeholder="아이디를 입력해 주세요."
      className={className}
    />
  )
}

export default IdInput
