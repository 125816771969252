import { ReactNode } from 'react'
import 'twin.macro'

interface Props {
  title: string
  children: ReactNode
}

const UserMetricCard = ({ title, children }: Props) => {
  return (
    <div tw="bg-white h-290 w-full rounded-md" className="shadowBase">
      <div tw="h-62 flex items-center justify-center flex-col text-18 font-pretendardSB">
        {title}
        <div tw="w-[87%] border-b-1 mt-10 border-gray-300" />
      </div>
      <div tw="flex justify-center">{children}</div>
    </div>
  )
}

export default UserMetricCard
