import { DisplayStandForm } from '@components/exhibition/FormFragments/DisplayStandFrom'
import { SculptureForm } from '@components/exhibition/FormFragments/SculptureForm'
import { RECOIL_KEY } from '@constant/recoilKey'
import { atom } from 'recoil'

export interface ExhibitionFormState {
  exhibitionId?: number
  exhibitionName: string
  sculptureFormValues: SculptureForm[]
  displayStandFormValues: DisplayStandForm[]
}

export const defaultExhibitionFormState: ExhibitionFormState = {
  exhibitionName: '',
  sculptureFormValues: [],
  displayStandFormValues: [],
}

export const exhibitionState = atom<ExhibitionFormState>({
  key: RECOIL_KEY.EXHIBITION,
  default: defaultExhibitionFormState,
  dangerouslyAllowMutability: true,
})
