import RouteLink from '@components/shared/RouteLink'
import { ROUTE_PATH } from '@constant/routePath'
import classNames from 'classnames'
import { ReactElement, ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import 'twin.macro'

export interface SidebarItemProps {
  children: ReactNode
  href: ROUTE_PATH
  highlightPath: ROUTE_PATH[]
  icon: ReactElement
  highlightIcon: ReactElement
}

const SidebarItem = ({
  children,
  href,
  highlightPath,
  highlightIcon,
  icon,
}: SidebarItemProps) => {
  const currentPathname = useLocation().pathname
  const isHighlightPath =
    href === currentPathname ||
    highlightPath.includes(currentPathname as ROUTE_PATH)

  return (
    <RouteLink to={href}>
      <button
        tw="w-full h-58 pl-77 flex gap-18 justify-start items-center"
        className={classNames(
          isHighlightPath && 'bg-[#EFE8DD] text-c-brown-300'
        )}
      >
        {isHighlightPath ? <span>{highlightIcon}</span> : <span>{icon}</span>}
        <span tw="font-pretendardSB text-17">{children}</span>
      </button>
    </RouteLink>
  )
}

export default SidebarItem
