export const deleteNotNecessaryProperty = <ReturnType>(
  obj: Record<string, any>
) => {
  const ret: Record<string, any> = {}
  for (const key in obj) {
    if (typeof obj[key] === 'number') {
      ret[key] = obj[key]
      continue
    }
    if (!obj[key]) continue
    if (typeof obj[key] === 'object') {
      if (obj[key].length === 0) continue
    }
    if (typeof obj[key] === 'string') {
      if (obj[key].length === 0) continue
    }
    ret[key] = obj[key]
  }
  return ret as ReturnType
}
