import { api } from '@api/index'
import FormError from '@components/form/FormError'
import PasswordConfirmInput from '@components/form/PasswordConfirmInput'
import PasswordInput from '@components/form/PasswordInput'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation } from '@tanstack/react-query'
import { FormProvider, useForm } from 'react-hook-form'
import 'twin.macro'

interface Props {
  onClose: Function
  userUUID: number
}

interface ChangePasswordForm {
  newPassword: string
  confirmPassword: string
}

const ChangePasswordModalContent = ({ onClose, userUUID }: Props) => {
  const { openModal } = useModal()
  const methods = useForm<ChangePasswordForm>({ mode: 'onChange' })
  const { handleSubmit } = methods

  const changePasswordMutation = useMutation(
    ['changePassword'],
    ({ newPassword, confirmPassword }: ChangePasswordForm) =>
      api.accountManagementService.changePassword(
        userUUID,
        newPassword,
        confirmPassword
      )
  )

  const handleChangePasswordFormSubmit = (formValue: ChangePasswordForm) => {
    changePasswordMutation.mutate(formValue, {
      onSuccess() {
        openModal((_, closeAllModal) => {
          return (
            <MessageModal onClose={closeAllModal}>
              {MESSAGE.success.updated.password}
            </MessageModal>
          )
        })
      },
    })
  }

  return (
    <div
      tw="w-550 h-250 bg-white rounded-md p-20 pt-40 flex flex-col"
      className="shadowBase"
    >
      <FormProvider {...methods}>
        <form
          id="change-password-form"
          tw="w-full flex flex-col under:w-420 items-center"
          onSubmit={handleSubmit(handleChangePasswordFormSubmit)}
        >
          <div tw="flex gap-20 items-center mr-70">
            <label tw="font-pretendardSB rounded-md basis-130 h-35 flex items-center justify-end">
              비밀번호
            </label>
            <PasswordInput
              id="newPassword"
              className="input base"
              tw="w-full"
            />
          </div>
          <FormError<ChangePasswordForm>
            tw="ml-160"
            targetInputId={'newPassword'}
          />
          <div tw="flex gap-20 items-center mr-70">
            <label tw="font-pretendardSB rounded-md basis-130 h-35 flex items-center justify-end">
              비밀번호 확인
            </label>
            <PasswordConfirmInput
              id="confirmPassword"
              className="input base"
              tw="w-full"
              passwordInputId={'newPassword'}
            />
          </div>
          <FormError<ChangePasswordForm>
            tw="ml-160"
            targetInputId={'confirmPassword'}
          />
        </form>
      </FormProvider>
      <div tw="mt-auto w-full flex items-center justify-center gap-22">
        <LoadableButton
          tw="w-120 h-40"
          className="button base lightBrown"
          type="submit"
          form="change-password-form"
          loadingKey=""
        >
          확인
        </LoadableButton>
        <button
          tw="w-120 h-40"
          onClick={() => onClose(false)}
          className="button base gray"
        >
          취소
        </button>
      </div>
    </div>
  )
}

export default ChangePasswordModalContent
