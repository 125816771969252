import { SculptureForm } from '@components/exhibition/FormFragments/SculptureForm'
import { exhibitionState } from '@store/exhibition'
import { useRecoilState } from 'recoil'

const useSculptureForms = () => {
  const [exhibitionForm, setExhibitionForm] = useRecoilState(exhibitionState)

  const setSculptureForm = (formValue: SculptureForm) => {
    const filteredPrevFormValues = exhibitionForm.sculptureFormValues.filter(
      ({ number }) => formValue.number !== number
    )
    exhibitionForm.sculptureFormValues = filteredPrevFormValues
    const currentLoadedExhibitionForm = { ...exhibitionForm }
    currentLoadedExhibitionForm.sculptureFormValues.push(formValue)
    setExhibitionForm(currentLoadedExhibitionForm)
  }

  const getSculptureForm = (formNumber: number) => {
    const finedSculptureForm = exhibitionForm.sculptureFormValues.find(
      ({ number }) => formNumber === number
    )
    return finedSculptureForm
  }

  return { getSculptureForm, setSculptureForm }
}

export { useSculptureForms }
