import { BadRequestError, NotAcceptable } from '@api/error/remoteError'
import { api } from '@api/index'
import ChangePasswordModalContent from '@components/account/ChangePasswordModalContent'
import AgeSelect from '@components/form/AgeSelect'
import AuthoritiesSelect from '@components/form/AuthoritiesSelect'
import FormError from '@components/form/FormError'
import GenderSelect from '@components/form/GenderSelect'
import NicknameInput from '@components/form/NicknameInput'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BackSpaceButton from '@components/shared/BackSpaceButton'
import {
  GoogleOAuth,
  KakaoOAuth,
  NaverOAuth,
  NoneOAuth,
} from '@components/shared/Icons'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import PageLoading from '@components/shared/PageLoading'
import { MESSAGE } from '@constant/message'
import { USER_ROLE } from '@constant/user'
import useAuth from '@hooks/useAuth'
import useModal from '@hooks/useModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dateFormat } from '@utils/dateFormat'
import classNames from 'classnames'
import moment from 'moment'
import queryString from 'query-string'
import { useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import 'twin.macro'

interface AccountUpdateForm {
  id: AccountInfo['id']
  nickname: AccountInfo['nickname']
  gender: AccountInfo['gender'] | 'pending'
  age: AccountInfo['age'] | 'pending'
  authorities: USER_ROLE | 'pending' | 'ROLE_BLOCK'
  provider: AccountInfo['provider']
}

const AccountDetail = () => {
  const { isSuperAdmin, isAdmin, user, requireReLogin } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const { id } = queryString.parse(location.search)
  const { openModal } = useModal()
  const queryClient = useQueryClient()

  const invalidateAccountInfo = () => {
    queryClient.invalidateQueries(['accountInfo', id])
  }

  useEffect(() => {
    if (!isSuperAdmin()) {
      if (!isAdmin() || user?.id !== Number(id)) {
        openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.error.forbidden.notSuperAdmin}
          </MessageModal>
        ))
        navigate('/admin/account-management')
      }
    }
  }, [])

  const methods = useForm<AccountUpdateForm>({
    mode: 'onChange',
    defaultValues: {
      gender: 'pending',
      age: 'pending',
      authorities: 'pending',
    },
  })

  const { handleSubmit, setValue, setError, getValues } = methods

  const isBlockedUser = getValues('authorities') === 'ROLE_BLOCK'

  const isOauthUser = getValues('provider') !== 'NONE'

  const { data: accountInfo, isFetching: isAccountInfoFetching } = useQuery(
    ['accountInfo', id],
    () => api.accountManagementService.getAccountDetail(Number(id)),
    {
      onSuccess({ id, nickname, gender, authorities, age, provider }) {
        setValue('provider', provider)
        setValue('id', id)
        setValue('nickname', nickname)
        setValue('gender', gender ?? 'pending')
        setValue('age', age ?? 'pending')
        setValue(
          'authorities',
          authorities[authorities.length - 1] ?? 'pending'
        )
      },
      enabled: !!id,
    }
  )

  const updateAccountMutation = useMutation(
    ['updateAccount'],
    (formValue: AccountUpdateForm) =>
      api.accountManagementService.updateAccount(
        formValue.id,
        formValue.gender,
        formValue.age,
        formValue.authorities as USER_ROLE,
        //새로 입력한 닉네임이 기존과 다를때만 업데이트 하기 위해 (닉네임 중복 에러 방지)
        formValue.nickname !== accountInfo?.nickname
          ? formValue.nickname
          : undefined
      )
  )

  const blockAccountMutation = useMutation(
    ['blockAccount'],
    () => api.accountManagementService.blockAccount(Number(id)),
    {
      onSuccess() {
        invalidateAccountInfo()
      },
      onError(err) {
        if (err instanceof NotAcceptable) {
          openModal((onClose) => (
            <MessageModal nonRes onClose={onClose}>
              차단 및 삭제가 불가능한 계정입니다.
            </MessageModal>
          ))
        }
      },
    }
  )

  const unblockAccountMutation = useMutation(
    ['unblockAccount'],
    () => api.accountManagementService.unblockAccount(Number(id)),
    {
      onSuccess() {
        invalidateAccountInfo()
      },
    }
  )

  const deleteAccountMutation = useMutation(
    ['deleteAccount'],
    () => api.accountManagementService.deleteAccount(Number(id)),
    {
      async onSuccess() {
        await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.deleted.account}
          </MessageModal>
        ))
        navigate('/admin/account-management')
      },
      onError(err) {
        if (err instanceof NotAcceptable) {
          openModal((onClose) => (
            <MessageModal nonRes onClose={onClose}>
              차단 및 삭제가 불가능한 계정입니다.
            </MessageModal>
          ))
        }
      },
    }
  )

  const handleFormSubmit = (formValue: AccountUpdateForm) => {
    updateAccountMutation.mutate(formValue, {
      async onSuccess() {
        const modalRes = await openModal((onClose) => (
          <MessageModal nonRes onClose={onClose}>
            {MESSAGE.success.updated.account}
          </MessageModal>
        ))
        if (modalRes) {
          navigate('/admin/account-management')
          if (user?.id === Number(id)) requireReLogin()
        }
      },
      onError(err) {
        if (err instanceof BadRequestError) {
          Object.entries(err.data.message).forEach(([field, message]) =>
            setError(field as any, {
              type: 'custom',
              message: message as string,
            })
          )
        }
      },
    })
  }

  const handleBlockAccountClick = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.blockAccount}
      </MessageModal>
    ))
    if (modalRes) blockAccountMutation.mutate()
  }

  const handleUnBlockAccountClick = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.unblockAccount}
      </MessageModal>
    ))
    if (modalRes) unblockAccountMutation.mutate()
  }

  const handleDeleteAccountClick = async () => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.deleteAccount}
      </MessageModal>
    ))
    if (modalRes) deleteAccountMutation.mutate()
  }

  const handleClickPasswordChange = () => {
    openModal((onClose) => (
      <ChangePasswordModalContent userUUID={Number(id)} onClose={onClose} />
    ))
  }

  return (
    <ContentWrapper breadCrumbPath={['계정관리', '상세정보']}>
      <CardSection className="relative min-h-830">
        {isAccountInfoFetching && <PageLoading covered />}
        <FormProvider<AccountUpdateForm> {...methods}>
          <form
            id="account-update-form"
            tw="w-full min-h-600 pt-150 flex-center gap-40 under:w-380"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <ul tw="under:h-70 under:flex under:items-start flex flex-col min-h-350">
              {accountInfo?.username && (
                <li>
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    아이디
                  </label>
                  <div tw="w-full" className="input view">
                    {accountInfo?.username}
                  </div>
                </li>
              )}
              {/* 현재 페이지의 유저가 소셜유저면 버튼 표시 하지 않기 */}
              {(isSuperAdmin() || user?.id === Number(id)) && !isOauthUser && (
                <li>
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    비밀번호
                  </label>
                  <button
                    type="button"
                    onClick={handleClickPasswordChange}
                    tw="w-full h-40 font-pretendardM"
                    className="button base lightGray"
                  >
                    변 경 하 기
                  </button>
                </li>
              )}
              <li tw="flex flex-col">
                <div tw="w-full flex items-center">
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    등급
                  </label>
                  {/* user id가 1번인 삭제 및 권한 수정 불가능한 어드민인 경우 수정을 하지 못하게 한다 */}
                  {/* 슈퍼 어드민이 아니고 자기 자신의 정보를 열람할때는 권한 수정을 하지 못하게 한다 */}
                  <AuthoritiesSelect
                    disabled={!isSuperAdmin() && user?.id === Number(id)}
                    id="authorities"
                    tw="w-full py-0 px-10 border-1"
                    className={classNames('input')}
                  />
                </div>
                <FormError<AccountUpdateForm>
                  tw="ml-110"
                  targetInputId="authorities"
                />
              </li>
              <li>
                <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                  연동 아이디
                </label>
                <div tw="w-full gap-8 items-center" className="input view">
                  {accountInfo?.provider === 'NONE' && (
                    <>
                      <NoneOAuth width={20} height={20} />
                      <div tw="text-gray-500">{accountInfo?.provider}</div>
                    </>
                  )}
                  {accountInfo?.provider === 'KAKAO' && (
                    <>
                      <KakaoOAuth width={20} height={20} />
                      <div>{accountInfo?.provider}</div>
                    </>
                  )}
                  {accountInfo?.provider === 'NAVER' && (
                    <>
                      <NaverOAuth width={20} height={20} />
                      <div>{accountInfo?.provider}</div>
                    </>
                  )}
                  {accountInfo?.provider === 'GOOGLE' && (
                    <>
                      <GoogleOAuth width={20} height={20} />
                      <div>{accountInfo?.provider}</div>
                    </>
                  )}
                </div>
              </li>
              <li tw="flex flex-col">
                <div tw="w-full flex items-center">
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    닉네임
                  </label>
                  <NicknameInput
                    id="nickname"
                    tw="w-full border-1"
                    className="input"
                  />
                </div>
                <FormError<AccountUpdateForm>
                  tw="ml-110"
                  targetInputId="nickname"
                />
              </li>
            </ul>
            <ul tw="mr-70 under:h-70 under:flex under:items-start flex flex-col min-h-350">
              <li tw="flex flex-col">
                <div tw="w-full flex items-center">
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    성별
                  </label>
                  <GenderSelect
                    id="gender"
                    tw="w-full py-0 px-10 border-1"
                    className="input"
                  />
                </div>
                <FormError<AccountUpdateForm>
                  tw="ml-110"
                  targetInputId="gender"
                />
              </li>

              <li tw="flex flex-col">
                <div tw="w-full flex items-center">
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    나이
                  </label>
                  <AgeSelect
                    id="age"
                    tw="w-full py-0 px-10 border-1"
                    className="input"
                  />
                </div>
                <FormError<AccountUpdateForm> tw="ml-110" targetInputId="age" />
              </li>
              {accountInfo?.email && (
                <li>
                  <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                    이메일
                  </label>
                  <div tw="w-full" className="input view">
                    {accountInfo?.email}
                  </div>
                </li>
              )}
              <li>
                <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                  가입일
                </label>
                <div tw="w-full" className="input view">
                  {accountInfo?.createdAt
                    ? dateFormat.fullKebab(moment(accountInfo?.createdAt))
                    : '없음'}
                </div>
              </li>
              <li>
                <label tw="h-40 w-120 flex items-center justify-end mr-15 font-pretendardSB text-[#343434]">
                  최종 접속일
                </label>
                <div tw="w-full" className="input view">
                  {accountInfo?.updatedAt
                    ? dateFormat.fullKebab(moment(accountInfo?.updatedAt))
                    : '없음'}
                </div>
              </li>
            </ul>
          </form>
        </FormProvider>
        <div tw="flex justify-center all:w-100 all:h-40 gap-20 all:font-pretendardM all:tracking-wider">
          <LoadableButton
            tw="px-20"
            className="button lightBrown"
            form="account-update-form"
            loadingKey={'updateAccount'}
          >
            수정
          </LoadableButton>
          {isBlockedUser ? (
            <LoadableButton
              onClick={handleUnBlockAccountClick}
              className="button base darkBrown"
              loadingKey={'unblockAccount'}
            >
              차단해제
            </LoadableButton>
          ) : (
            <LoadableButton
              onClick={handleBlockAccountClick}
              className="button base darkBrown"
              loadingKey={'blockAccount'}
            >
              차단
            </LoadableButton>
          )}
          <LoadableButton
            onClick={handleDeleteAccountClick}
            className="button base darkBrown"
            loadingKey={'deleteAccount'}
          >
            삭제
          </LoadableButton>
          <BackSpaceButton className="button base darkBrown">
            취소
          </BackSpaceButton>
        </div>
      </CardSection>
    </ContentWrapper>
  )
}

export default AccountDetail
