import { api } from '@api/index'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BaseTable, { Column } from '@components/shared/BaseTable'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import PageLoading from '@components/shared/PageLoading'
import PaginationBar from '@components/shared/PaginationBar'
import RouteLink from '@components/shared/RouteLink'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dateFormat } from '@utils/dateFormat'
import classNames from 'classnames'
import moment from 'moment'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import usePagination from 'react-use-pagination-hook'
import 'twin.macro'

const TABLE_ROW_SIZE = 14

const MediaManagement = () => {
  const loc = useLocation()
  const { init_type } = queryString.parse(loc.search)
  const [listFilter, setListFilter] = useState<'FILE' | 'URL'>('URL')
  const isVideoListMode = listFilter === 'URL'
  const isPdfListMode = listFilter === 'FILE'
  const { openModal } = useModal()

  useEffect(() => {
    if (init_type) {
      if (init_type === 'file') setListFilter('FILE')
      if (init_type === 'url') setListFilter('URL')
    }
  }, [])

  const queryClient = useQueryClient()

  const mediaListColumn: Column = [
    ['순번', 10],
    ['제목', 25],
    ['URL', 35],
    ['등록일자', 15],
    ['', 15],
  ]

  const docsListColumn: Column = [
    ['순번', 10],
    ['제목', 25],
    ['URL', 40],
    ['등록일자', 15],
    ['', 15],
  ]

  const paginationMethods = usePagination({ numOfPage: 5 })
  const { setTotalPage, currentPage } = paginationMethods

  const invalidateCurrentPageQuery = () => {
    queryClient.invalidateQueries(['mediaList', currentPage, listFilter])
  }

  const { data: mediaList, isLoading: isMediaListLoading } = useQuery(
    ['mediaList', currentPage, listFilter],
    () =>
      api.mediaService.getMediaList(
        listFilter,
        currentPage - 1,
        TABLE_ROW_SIZE
      ),
    {
      onSuccess(paginationResponse) {
        setTotalPage(paginationResponse.totalPages)
      },
    }
  )

  const deleteMediaMutation = useMutation(
    ['deleteMedia'],
    (mediaUUID: number) => api.mediaService.deleteMedia(mediaUUID)
  )

  const handleDeleteMediaClick = async (mediaUUID: number) => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.deleteMedia}
      </MessageModal>
    ))
    if (modalRes) {
      deleteMediaMutation.mutate(mediaUUID, {
        async onSuccess() {
          openModal((onClose) => (
            <MessageModal nonRes onClose={onClose}>
              {MESSAGE.success.deleted.media}
            </MessageModal>
          ))
          invalidateCurrentPageQuery()
        },
      })
    }
  }

  return (
    <ContentWrapper breadCrumbPath={['미디어']}>
      <CardSection>
        <div tw="flex items-center mt-15 gap-10">
          <button
            onClick={() => setListFilter('URL')}
            tw="w-120"
            className={classNames(
              'button base',
              isVideoListMode ? 'yellow' : 'lightGray'
            )}
          >
            동영상
          </button>
          <button
            onClick={() => setListFilter('FILE')}
            tw="w-120"
            className={classNames(
              'button base',
              isPdfListMode ? 'yellow' : 'lightGray'
            )}
          >
            PDF 문서
          </button>
          {isVideoListMode && (
            <RouteLink tw="ml-auto" to={'/admin/media-management/add-video'}>
              <button className="button base darkBrown">동영상 추가</button>
            </RouteLink>
          )}
          {isPdfListMode && (
            <RouteLink tw="ml-auto" to={'/admin/media-management/add-docs'}>
              <button className="button base darkBrown">문서 추가</button>
            </RouteLink>
          )}
        </div>
        <div tw="h-690 w-full mt-25">
          {isMediaListLoading && <PageLoading />}
          {!isMediaListLoading && (
            <BaseTable
              emptyMessage="미디어가 없습니다."
              column={isVideoListMode ? mediaListColumn : docsListColumn}
            >
              {mediaList?.content.map(({ id, url, createdAt, title }) => (
                <tr key={id}>
                  <td>{id}</td>
                  <td>{title}</td>
                  <td>
                    <a
                      target="__blank"
                      tw="underline text-c-brown-300"
                      href={url}
                    >
                      {url}
                    </a>
                  </td>
                  <td>{dateFormat.fullKebab(moment(createdAt))}</td>
                  <td tw="flex justify-center">
                    <LoadableButton
                      loadingSize={22}
                      onClick={() => handleDeleteMediaClick(id)}
                      className="button base gray"
                      loadingKey={'deleteMedia'}
                    >
                      삭제
                    </LoadableButton>
                  </td>
                </tr>
              ))}
            </BaseTable>
          )}
        </div>
      </CardSection>
      <PaginationBar tw="mt-17" {...paginationMethods} />
    </ContentWrapper>
  )
}

export default MediaManagement
