import { useState } from 'react'
const useErrorBoundary = () => {
  const [error, throwOnBoundary] = useState<any | null>(null)

  if (error != null) {
    throw error
  }

  return { throwOnBoundary }
}

export default useErrorBoundary
