const win = typeof window !== `undefined` ? window : ({} as Storage)

export const localStorage = win.localStorage
export const sessionStorage = win.sessionStorage

export type StorageDeleteMethod = (key: string) => void
export type StorageGetMethod = (key: string) => unknown
export type StorageSetMethod = (
  key: string,
  data: object | number | string
) => void
