import { ReactNode } from 'react'
import 'twin.macro'
import { TwStyle } from 'twin.macro'
import Sidebar from '../Sidebar'
import TopMenu from '../TopMenu'

interface Props {
  children: ReactNode
  mainStyle?: TwStyle
  breadCrumbPath: string[]
}

const ContentWrapper = ({ children, mainStyle, breadCrumbPath }: Props) => {
  return (
    <section tw="w-full min-h-938 p-10 flex">
      <Sidebar />
      <main
        css={mainStyle}
        className="rounded-md shadowBase"
        tw="w-1280 bg-[#EFE8DD] h-full px-25 py-15 overflow-x-hidden"
      >
        <TopMenu breadCrumbPath={breadCrumbPath} />
        {children}
      </main>
    </section>
  )
}

export default ContentWrapper
