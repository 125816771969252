import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { MediaService } from './mediaService'

export const mediaServiceRemote = (): MediaService => ({
  async addVideo(title, url) {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('url', url)
    try {
      const response = await baseAPI.post({
        url: `/media`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async addDocs(title, file) {
    const formData = new FormData()
    formData.append('title', title)
    formData.append('file', file)
    try {
      const response = await baseAPI.post({
        url: `/media`,
        type: 'multipart',
        data: formData,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getMediaList(type, page, size) {
    try {
      const response = await baseAPI.get({
        url: `/media`,
        params: { type, page, size },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async deleteMedia(id) {
    try {
      const response = await baseAPI.delete({
        url: `/media/${id}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
