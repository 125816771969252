import { ROUTE_PATH } from '@constant/routePath'
import _404Page from '@pages/404Page'
import AccountDetail from '@pages/AccountDetail'
import AccountManagement from '@pages/AccountManagement'
import AddDocs from '@pages/AddDocs'
import AddExhibition from '@pages/AddExhibition'
import AddVideo from '@pages/AddVideo'
import BoardManagement from '@pages/BoardManagement'
import CreateAdmin from '@pages/CreateAdmin'
import DashBoard from '@pages/DashBoard'
import ExhibitionManagement from '@pages/ExhibitionManagement'
import GuestBookManagement from '@pages/GuestBookManagement'
import MediaManagement from '@pages/MediaManagement'
import { Route, Routes } from 'react-router-dom'
import PrivateRouter from './PrivateRouter'

const CURRENT_ROUTER_FULL_PATH = '/admin'

const relativePath = (path: ROUTE_PATH) => {
  const regexp = new RegExp(`${CURRENT_ROUTER_FULL_PATH}`)
  return path.replace(regexp, '')
}

const AdminRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<PrivateRouter />}>
        <Route path={relativePath('/admin')} element={<DashBoard />} />
        <Route
          path={relativePath('/admin/account-management')}
          element={<AccountManagement />}
        />
        <Route
          path={relativePath('/admin/account-management/detail')}
          element={<AccountDetail />}
        />
        <Route
          path={relativePath('/admin/account-management/create-admin')}
          element={<CreateAdmin />}
        />
        <Route
          path={relativePath('/admin/board-management')}
          element={<BoardManagement />}
        />
        <Route
          path={relativePath('/admin/exhibition-management')}
          element={<ExhibitionManagement />}
        />
        <Route
          path={relativePath('/admin/exhibition-management/add')}
          element={<AddExhibition />}
        />
        <Route
          path={relativePath('/admin/guest-book-management')}
          element={<GuestBookManagement />}
        />
        <Route
          path={relativePath('/admin/media-management')}
          element={<MediaManagement />}
        />
        <Route
          path={relativePath('/admin/media-management/add-docs')}
          element={<AddDocs />}
        />
        <Route
          path={relativePath('/admin/media-management/add-video')}
          element={<AddVideo />}
        />
        <Route path="/*" element={<_404Page />} />
      </Route>
    </Routes>
  )
}

export default AdminRouter
