/**
 * input 필드에 적용된 maxLength 속성에 맞추어 input value를 잘라서 반환
 * @param tgtObj 이벤트 객체
 * @returns 타겟 필드의 maxLength 속성 값에 맞게 잘려진 input value
 */
export const sliceToMaxLength = (
  tgtObj: React.ChangeEvent<HTMLInputElement>
) => {
  if (tgtObj.target.value.length >= tgtObj.target.maxLength) {
    tgtObj.target.value = tgtObj.target.value.slice(0, tgtObj.target.maxLength)
    return tgtObj.target.value
  }
  return tgtObj.target.value
}
