import { api } from '@api/index'
import AgeRatioChart from '@components/dashboard/AgeRatioChart'
import GenderRatioChart from '@components/dashboard/GenderRatioChart'
import RAUCards from '@components/dashboard/RAUCards'
import UserMetricCard from '@components/dashboard/UserMetricCard'
import VisitMetricCard from '@components/dashboard/VisitMetricCard'
import ContentWrapper from '@components/layout/ContentWrapper'
import LoadingDot from '@components/shared/LoadingDot'
import { useQuery } from '@tanstack/react-query'
import 'twin.macro'
import tw from 'twin.macro'

const DashBoard = () => {
  const { data: genderMetric, isLoading: genderMetricLoading } = useQuery(
    ['genderMetric'],
    api.statisticsService.getGenderMetric,
    {
      cacheTime: 0,
    }
  )

  const { data: ageMetric, isLoading: ageMetricLoading } = useQuery(
    ['ageMetric'],
    api.statisticsService.getAgeMetric,
    {
      cacheTime: 0,
    }
  )

  return (
    <ContentWrapper mainStyle={tw`min-h-915`} breadCrumbPath={['대시보드']}>
      <VisitMetricCard />
      <RAUCards />
      <div tw="mt-20 flex gap-20">
        <UserMetricCard title="남녀 유저 현황">
          {genderMetricLoading && (
            <div tw="w-full h-200 flex justify-center items-center ">
              <LoadingDot />
            </div>
          )}
          {genderMetric && <GenderRatioChart data={genderMetric} />}
        </UserMetricCard>
        <UserMetricCard title="나이별 유저 현황">
          {ageMetricLoading && (
            <div tw="w-full h-200 flex justify-center items-center ">
              <LoadingDot />
            </div>
          )}
          {ageMetric && <AgeRatioChart data={ageMetric} />}
        </UserMetricCard>
      </div>
    </ContentWrapper>
  )
}

export default DashBoard
