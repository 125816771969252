import _404Page from '@pages/404Page'
import Landing from '@pages/Landing'
import { Route, Routes } from 'react-router-dom'

const LandingRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/*" element={<_404Page />} />
    </Routes>
  )
}

export default LandingRouter
