import { MESSAGE } from '@constant/message'
import { sliceToMaxLength } from '@utils/sliceToMaxLength'
import { useFormContext } from 'react-hook-form'

interface Props extends ReactInput {
  id: string
  passwordInputId: string
}

const PasswordConfirmInput = ({ id, className, passwordInputId }: Props) => {
  const { register, getValues } = useFormContext()
  return (
    <input
      id={id}
      {...register(id, {
        required: MESSAGE.user.require.passwordConfirm,
        validate(value) {
          return (
            value === getValues(passwordInputId) ||
            MESSAGE.user.invalid.passwordConfirm
          )
        },
      })}
      maxLength={16}
      onInput={sliceToMaxLength}
      placeholder="비밀번호 확인을 입력해 주세요."
      className={className}
      type="password"
    />
  )
}

export default PasswordConfirmInput
