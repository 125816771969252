import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { StatisticsService } from './statisticsService'

export const statisticsServiceRemote = (): StatisticsService => ({
  async getRAU() {
    try {
      const response = await baseAPI.get({
        url: `/admin/statistics/rau`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getGenderMetric() {
    try {
      const response = await baseAPI.get({
        url: `/admin/statistics/gender-statistics`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getAgeMetric() {
    try {
      const response = await baseAPI.get({
        url: `/admin/statistics/age-statistics`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getMonthlyVisited(baseDate) {
    try {
      const response = await baseAPI.get({
        url: `/admin/statistics/visit/monthly/${baseDate}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  async getYearlyVisited(baseDate) {
    try {
      const response = await baseAPI.get({
        url: `/admin/statistics/visit/yearly/${baseDate}`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
})
