import { baseAPI } from '@api/core'
import { throwRemoteError } from '@api/error/remoteError'
import { AuthService } from './authService'

export const authServiceRemote = (): AuthService => ({
  login: async (username, password) => {
    try {
      const response = await baseAPI.post({
        url: `/auth/authorize`,
        data: { username, password },
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  tokenToUser: async (token) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    try {
      const response = await baseAPI.get({
        headers,
        url: `/user`,
      })
      baseAPI.setDefaultAuthorizationHeader(token)
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  logout: async () => {
    try {
      const response = await baseAPI.delete({
        url: `/auth/token`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  refreshToken: async (token) => {
    const headers = {
      Authorization: `Bearer ${token}`,
    }
    try {
      const response = await baseAPI.get({
        headers,
        url: `/auth/refresh`,
      })
      return response.data
    } catch (error) {
      throwRemoteError(error)
    }
  },
  deleteDefaultAuthorizationHeader: baseAPI.deleteDefaultAuthorizationHeader,
})
