import { api } from '@api/index'
import CardSection from '@components/layout/CardSection'
import ContentWrapper from '@components/layout/ContentWrapper'
import BaseTable, { Column } from '@components/shared/BaseTable'
import LoadableButton from '@components/shared/LoadableButton'
import MessageModal from '@components/shared/MessageModal'
import PageLoading from '@components/shared/PageLoading'
import PaginationBar from '@components/shared/PaginationBar'
import RouteLink from '@components/shared/RouteLink'
import SearchBar from '@components/shared/SearchBar'
import { MESSAGE } from '@constant/message'
import useModal from '@hooks/useModal'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { dateFormat } from '@utils/dateFormat'
import moment from 'moment'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import usePagination from 'react-use-pagination-hook'
import 'twin.macro'

const TABLE_ROW_SIZE = 14

const ExhibitionManagement = () => {
  const navigate = useNavigate()
  const [searchKey, setSearchKey] = useState('')
  const tableColumn: Column = [
    ['순번', 15],
    ['기획전 이름', 30],
    ['등록일자', 20],
    ['', 15],
  ]

  const paginationMethods = usePagination({ numOfPage: 5 })
  const { openModal } = useModal()
  const { setTotalPage, currentPage } = paginationMethods

  const queryClient = useQueryClient()

  const { data: exhibitedExhibition } = useQuery(
    ['exhibitedExhibition'],
    api.exhibitionService.exhibitedExhibition
  )

  const { data: exhibitionList, isLoading: isExhibitionLoading } = useQuery(
    ['exhibitionList', currentPage, searchKey],
    () =>
      api.exhibitionService.exhibitionList(
        currentPage - 1,
        TABLE_ROW_SIZE,
        searchKey
      ),
    {
      onSuccess(paginationResponse) {
        setTotalPage(paginationResponse.totalPages)
      },
    }
  )

  const invalidExhibitionListQuery = () => {
    queryClient.invalidateQueries(['exhibitionList', currentPage, searchKey])
    queryClient.invalidateQueries(['exhibitedExhibition'])
  }

  const deleteExhibitionMutation = useMutation(
    ['deleteExhibition'],
    (exhibitionUUID: number) =>
      api.exhibitionService.deleteExhibition(exhibitionUUID),
    {
      onSuccess() {
        invalidExhibitionListQuery()
      },
    }
  )

  const exhibitExhibition = useMutation(
    ['exhibitExhibition'],
    (exhibitionUUID: number) =>
      api.exhibitionService.exhibitExhibition(exhibitionUUID),
    {
      onSuccess() {
        invalidExhibitionListQuery()
      },
    }
  )

  const handleListRowClick = (exhibitionUUID: number) => {
    navigate(
      `/admin/exhibition-management/add?mode=modify&id=${exhibitionUUID}`
    )
  }

  const handleExhibitClick = async (exhibitionUUID: number) => {
    const res = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.updateStateExhibition}
      </MessageModal>
    ))
    if (res)
      exhibitExhibition.mutate(exhibitionUUID, {
        onSuccess() {
          openModal((onClose) => (
            <MessageModal onClose={onClose}>
              {MESSAGE.success.updated.exhibited}
            </MessageModal>
          ))
        },
      })
  }

  const handleDeleteClick = async (exhibitionUUID: number) => {
    const modalRes = await openModal((onClose) => (
      <MessageModal onClose={onClose}>
        {MESSAGE.question.deleteExhibition}
      </MessageModal>
    ))
    if (modalRes)
      deleteExhibitionMutation.mutate(exhibitionUUID, {
        async onSuccess() {
          await openModal((onClose) => (
            <MessageModal onClose={onClose}>
              {MESSAGE.success.deleted.exhibition}
            </MessageModal>
          ))
        },
        onError() {},
      })
  }

  return (
    <ContentWrapper breadCrumbPath={['기획전시실']}>
      <CardSection>
        <div tw="flex items-center mt-15 gap-10">
          <SearchBar<ExhibitionInfo>
            tw="w-350"
            searchKey="name"
            placeholder="기획전 이름 검색"
            onSearch={setSearchKey}
          />
          <RouteLink tw="ml-auto" to={'/admin/exhibition-management/add'}>
            <button className="button base darkBrown">기획전 추가</button>
          </RouteLink>
        </div>
        <div tw="h-690 w-full mt-25">
          {isExhibitionLoading && <PageLoading />}
          {!isExhibitionLoading && (
            <BaseTable
              emptyMessage="기획전시실이 없습니다"
              column={tableColumn}
            >
              {exhibitedExhibition && (
                <tr
                  onClick={() => handleListRowClick(exhibitedExhibition.id)}
                  tw="cursor-pointer hover:bg-slate-50"
                >
                  <td>{exhibitedExhibition.id}</td>
                  <td tw="flex justify-center items-center h-43">
                    <div tw="max-w-320">{exhibitedExhibition.name}</div>
                    <div tw="ml-10 text-red-500 font-pretendardSB">{`[ 전시중 ]`}</div>
                  </td>
                  <td>
                    {dateFormat.fullKebab(
                      moment(exhibitedExhibition.createdAt)
                    )}
                  </td>
                  <td></td>
                </tr>
              )}
              {exhibitionList?.content.map(({ id, name, createdAt }) => (
                <tr
                  tw="cursor-pointer hover:bg-slate-50"
                  onClick={() => handleListRowClick(id)}
                  key={id}
                >
                  <td>{id}</td>
                  <td>{name}</td>
                  <td>{dateFormat.fullKebab(moment(createdAt))}</td>
                  <td onClick={(e) => e.stopPropagation()}>
                    <div tw="flex gap-10 items-center justify-center">
                      <LoadableButton
                        onClick={() => handleExhibitClick(id)}
                        className="px-10 py-3 button lightBrown"
                        loadingKey={''}
                      >
                        전시
                      </LoadableButton>
                      <LoadableButton
                        onClick={() => handleDeleteClick(id)}
                        className="px-10 py-3 button lightGray"
                        loadingKey={''}
                      >
                        삭제
                      </LoadableButton>
                    </div>
                  </td>
                </tr>
              ))}
            </BaseTable>
          )}
        </div>
      </CardSection>
      <PaginationBar tw="mt-17" {...paginationMethods} />
    </ContentWrapper>
  )
}

export default ExhibitionManagement
