import {
  ForbiddenError,
  NotFoundError,
  UnauthorizedError,
} from '@api/error/remoteError'
import useAuth from '@hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import 'twin.macro'

interface Props {
  error: Error
  resetBoundary?: () => void
}

const GlobalErrorFallback = ({ error, resetBoundary }: Props) => {
  const navigate = useNavigate()
  const { replaceRootPathCause401Error, replaceRootPathCause403Error } =
    useAuth()

  const handleGoToHome = () => {
    resetBoundary?.()
    navigate('/admin')
  }

  if (error instanceof UnauthorizedError) {
    replaceRootPathCause401Error()
    return null
  }

  if (error instanceof ForbiddenError) {
    replaceRootPathCause403Error()
    return null
  }

  if (error instanceof NotFoundError) {
    return (
      <>
        <div>{error.status}</div>
        <div>{error.description}</div>
      </>
    )
  }

  /**
   * 알 수 없는 에러의 경우
   */
  return (
    <div tw="w-full h-full flex items-center justify-center flex-col gap-15">
      <h2 tw="text-20">에러가 발생하였습니다.</h2>
      <button
        onClick={handleGoToHome}
        className="h-40 button base darkBrown w-300"
      >
        홈으로 돌아가기
      </button>
    </div>
  )
}

export default GlobalErrorFallback
